import styled from "../../styles/styled-components";
import { PlayerProps } from "./Player";
import { mediaQueries } from "../../styles/media-queries";

const PlayerStyle = styled.div`
	position: relative;
	cursor: pointer;
	width: 70px;
	height: 50px;
	text-align: ${(props: any) => !props.player ? 'center' : 'inherit'};

	@media ${mediaQueries.mobileM} {
		width: 60px;
		height: 55px;
	}

	@media ${mediaQueries.tablet} {
		width: 75px;
		height: 70px;
	}

	.position-label {
		position: absolute;
    top: 135%;
    margin: 0 auto;
    text-align: center;
    left: 40%;
    color: white;
	}
`;

const PlayerBg = styled.img`
	position: absolute;
	top: 4%;
	left: 0;
	right: 0;
	bottom: 25%;
	opacity: ${(props: any) => props.inactive ? 0.6 : 1};
	max-width: 55%;
	margin: 0 auto;

	@media ${mediaQueries.mobileM} {
		max-width: 65%;
	}

` as any;

const ClubBadgeBg = styled.img`
	position: absolute;
	top: 40%;
	z-index: 2;
	opacity: ${(props: any) => props.inactive ? 0.6 : 1};
	max-width: 30%;
	margin: 0 auto;
` as any;

const SoccerJersey = styled.div`
	position: absolute;
	top: 63%;
	left: 0;
	right: 0;
	bottom: 25%;
	background: url(${(props: any) => props.bg}) no-repeat center center/contain;
	max-width: 65%;
	margin: 0 auto;
` as any;

const Badge = styled.div`
	position: absolute;
	top: 75%;
	bottom: 0;
	left: 0;
	right: 0;

	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${(props: any) => props.bgColor};

	@media ${mediaQueries.tablet} {
		padding: 0 5px;
		padding-right: ${(props: any) => props.paddingRight}px;
		overflow: hidden;
	}

	span {
		font-weight: 400;
		color: black;
		line-height: 1.1;
		font-size: 9px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		margin: 0;

		@media ${mediaQueries.mobileM} {
			font-size: 10px;
			margin: 2px;
			margin-top: 4px;
		}
		color: ${(props: any) => props.color};

		@media ${mediaQueries.tablet} {
			font-family: inherit;
			font-size: 11px;
			font-weight: bold;
		}
	}
` as any;

const OpponentBadge = styled.div`
	position: absolute;
	top: 100%;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 20px;
	
	background: rgba(0,0,0,0.4);
	color: #fff /*#222A33 /* #38003D */;
	padding: 3px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	@media ${mediaQueries.tablet} {
		padding-right: ${(props: any) => props.paddingRight}px;
		overflow: hidden;
	}
` as any;

const Value = styled.div`
	top: 100%;
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	width: 100%;
	height: 20px;

	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0,0,0,0.4);
	color: #fff;

	h4 {
		font-family: C-Regular,"Helvetica Neue",Helvetica,Arial,sans-serif;
		font-weight: 400;
		color: ${(props: any) => props.benchPlayer ? '#000' : '#000'};
		line-height: 1.1;
		white-space: nowrap;
		margin: 0;
    	height: 10px;

		@media ${mediaQueries.mobileM} {
		}

		@media ${mediaQueries.tablet} {
			font-family: inherit;
			font-weight: bold;
		}
	}
` as any;

const Points = styled.span`
	font-family: C-Regular,"Helvetica Neue",Helvetica,Arial,sans-serif;
	font-weight: bold;
	color: ${(props: any) => props.color};
	background-color: ${(props: any) => props.bgColor};;
	align-self: flex-end;

	position: absolute;
	right: 0px;
	top: 40%;
	width: 20px;
	height: 20px;

	line-height: 1;

	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
` as any;

const NoPlayer = styled.div`
	margin: 0 auto;
` as any;

const TopRightAction = styled.div`
	position: absolute;
	right: 10px;
	width: 20px;
	height: 20px;
	text-align: center;
	cursor: pointer;
	
	@media ${mediaQueries.mobileM} {
		right: 0px;
	}
` as any;

const TopLeftAction = styled.div`
	position: absolute;
	left: 10px;
	width: 20px;
	height: 20px;
	text-align: center;
	cursor: pointer;
	
	@media ${mediaQueries.mobileM} {
		left: 0px;
	}

	&.delete {
		top: 0px;
	}
` as any;

const BottomRightAction = styled.div`
	position: absolute;
	right: 0px;
	bottom: 20px;
	width: 20px;
	height: 20px;
	text-align: center;
	cursor: pointer;
` as any;

export {
	PlayerStyle,
	PlayerBg,
	SoccerJersey,
	Badge,
	Points,
	NoPlayer,
	TopRightAction,
	Value,
	OpponentBadge,
	ClubBadgeBg,
	TopLeftAction,
	BottomRightAction
};