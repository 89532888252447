import styled, {keyframes} from "../../styles/styled-components";
import {mediaQueries, sizes} from "../../styles/media-queries";

const FooterStyle = styled.footer`
	display: none;

	@media ${mediaQueries.mobileL} {
		display: block;
	}

	padding: 10px;
	height: 100px;
	background-color: #000;

	ul {
		list-style: none;
		display: flex;
    	align-items: stretch;
		justify-content: space-between;
		padding: 0px;

		li {
			display: block;
			flex: 0 1 auto;
			list-style-type: none;
			width: 100%;
			text-align: center;
			padding: 5px;

			a {
				color: white;
				border-bottom: none;
				
				.anticon {
					margin-right: 5px;
				}
			}
		}
	}
` as any;

export {
	FooterStyle,
};