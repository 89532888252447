import React, {Component} from "react";
import {AlertStyle} from "./AlertStyle";
import {AlertProps} from "antd/lib/alert";

export const Alert = (props: any) => {
	const {children, ...rest} = props;

	return (<AlertStyle
		{...rest}>
		{children}
	</AlertStyle>);
};

export default Alert;
