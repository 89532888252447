import React from 'react';
import {Spin} from 'antd';
import {SpinnerIcon} from './SpinnerStyle';
import {SpinProps} from 'antd/lib/spin';

interface Props {
	spin?: boolean
}

type MySpinProps = SpinProps & Props;
export const Spinner = (props: MySpinProps) => {
	const icon = <SpinnerIcon type="loading" spin={false}/>;
	return (props.spin && <Spin indicator={icon}/>) || null;
};

export default Spinner;
