import styled from "styled-components";
import {Form} from 'antd';

import 'antd/lib/form/style';

export const FormStyle = styled(Form)`
    .ant-form label {
        line-height: 20px;
    }
`;

export const FormItemStyle = styled(Form.Item)`
    margin-bottom: 0;

    .ant-form-item-label > label {
        color: #FFF;
    }
`;