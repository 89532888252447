import React, {Component} from 'react';
import {PlayerType} from '../Player/Player';
import {PortraitStyle, PortraitBg, PortraitSoccerJersey, PortraitIcon} from './PlayerPortraitStyle';

export interface PlayerPortraitProps {
	icon: string;
	smallBottomLeftIcon?: string;
	portraitFace?: string;
	size?: string;
	parentSize?: string;
	type: PlayerType;
	style?: any
}

export interface PlayerPortraitState {
}

class PlayerPortrait extends Component<PlayerPortraitProps, PlayerPortraitState> {
	render() {
		const {type, icon, smallBottomLeftIcon, portraitFace, size, style} = this.props;
		const iconSize = !size ? '50px' : size;
		return (
			<PortraitStyle style={style}>
				{(type === PlayerType.Cycling || type === PlayerType.Cyclocross) && <PortraitBg bg={icon} size={iconSize}/>}
				{type === PlayerType.SoccerShirt && <PortraitBg bg={icon} size={iconSize}/>}
				{type === PlayerType.SoccerPortrait && <PortraitBg bg={portraitFace} size={iconSize}/>}
				{type === PlayerType.SoccerPortrait && <PortraitSoccerJersey bg={icon}/>}
				{smallBottomLeftIcon && <PortraitIcon icon={smallBottomLeftIcon}/>}
			</PortraitStyle>
		);
	}
}

export default PlayerPortrait;
