import React, {Component} from "react";
import {ButtonStyle} from "./ButtonStyle";
import {ButtonProps} from "antd/lib/button";

export const Button = (props: any) => {
	const {children, ...rest} = props;

	return (<ButtonStyle
		{...rest}>
		{children}
	</ButtonStyle>);
};

export default Button;
