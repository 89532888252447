import styled from "../../styles/styled-components";
import { mediaQueries } from "../../styles/media-queries";
import { H2, H1 } from "../../styles/global";

const NewTeamStyles = styled.div`

`;

export {
	NewTeamStyles
};