import styled from '../../../styles/styled-components';

const H1Style = styled.h1`
	font-size: 30px;
	color: #FFFFFF !important;
	text-transform: uppercase;
`;

const H2Style = styled.h2`
	font-size: 24px;
	font-family: HLN-Bold;
	color: #FFFFFF !important;
`;

const H3Style = styled.h1`
	font-size: 20px;
	color: #000 !important;
	text-transform: uppercase;
`;

const H4Style = styled.h1`
	font-size: 18px;
	color: #FFFFFF !important;
`;

export {
	H1Style,
	H2Style,
	H3Style,
	H4Style
};