import styled from "../../styles/styled-components";
import { mediaQueries } from "../../styles/media-queries";

const SidebarStyle = styled.div`
	background-color: #F0F3F8;

	a {
		display: block;
		padding: 15px;
		line-height: 0;

		&.active {
			background-color: ${(props: any) => props.theme.secondaryColor};
			color: ${(props: any) => props.theme.primaryContrast};
		}

		&.active-primary {
			background-color: ${(props: any) => props.theme.primaryColor};
			color: #FFF;
		}

		.anticon-caret-right {
			float: right;
		}

		.anticon-credit-card {
			margin-right: 5px;
		}

		.team-name {
			white-space: nowrap;
    		overflow: hidden;
    		text-overflow: ellipsis;
    		width: 70%;
			display: inline-block;
			
			.badge {
				margin-right: 5px;

				.anticon-check-circle {
					color: green;
				}
		
				.anticon-exclamation-circle {
					color: red;
				}
			}
		}
	}

	.ant-divider-horizontal {
		margin: 0px;
	}
`;
export {
	SidebarStyle,
};