import React, {Component} from "react";
import {SubstitutesStyle, Title} from "./SubstitutesStyle";
import Player, {PlayerType} from "../Player/Player";
import {Club} from './../../types';

interface SubstitutesState {
}

interface SubstitutesProps {
	title: string;
	bgColor?: string;
	playerType: PlayerType;
	selection: any;
	playerBadgeColor: string;
	playerBadgeBgColor: string;
	playerPointsColor: string;
	playerPointsBgColor: string;
	onRemove?: any;
	onSwap?: any;
	isSwapAble?: any;
	swapPlayerId?: number | null;
	swappedFrom?: string | null;
	assetsCdn: string;
	onPlaceholderClick?: any;
	actionLessPlayerIds?: any[];
	showPlayerValue?: boolean;
	showPlayerValueInsteadOfPoints?: boolean;
	clubs: Club[];
	modalEnabled?: boolean;
}

class Substitutes extends Component<SubstitutesProps, SubstitutesState> {
	render() {
		const {
			title, bgColor, selection, playerType, playerBadgeColor, playerBadgeBgColor, playerPointsColor,
			playerPointsBgColor, onRemove, onSwap, swapPlayerId, swappedFrom, assetsCdn, onPlaceholderClick,
			actionLessPlayerIds, showPlayerValue,showPlayerValueInsteadOfPoints, clubs, modalEnabled, isSwapAble
		} = this.props;

		return (
			<SubstitutesStyle bgColor={bgColor}>
				<Title>{title}</Title>
				<div className="substitutes">
					{selection.map((player: any, index: number) => {
						const sportSpecificProps: { shirtCycling?: string, shirtSoccer?: string, shirtFallback?: string; club?: Club } = {};

						if ([PlayerType.Cycling, PlayerType.SoccerShirt, PlayerType.Cyclocross].includes(playerType) && player) {
							const club = clubs.find((item: Club, index: number) => item.id === player.clubId);

							if (PlayerType.Cycling === playerType || PlayerType.Cyclocross === playerType) {
								sportSpecificProps.shirtCycling = `${assetsCdn}/jerseys/cycling/${player.clubId}.png`;
							}

							if (PlayerType.SoccerShirt === playerType) {
								sportSpecificProps.shirtSoccer = `${assetsCdn}/jerseys/football/${player.clubId}.png`;
							}

							sportSpecificProps.shirtFallback = `${assetsCdn}/jerseys/dummy.png`;
							sportSpecificProps.club = club;
						}

						return <Player
							key={`substitutes-${index}`}
							pointsColor={playerPointsColor}
							pointsBgColor={playerPointsBgColor}
							badgeColor={playerBadgeColor}
							badgeBgColor={playerBadgeBgColor}
							player={player}
							type={playerType}
							modalEnabled={modalEnabled}
							showPlayerValue={showPlayerValue}
							onRemove={onRemove}
							isSwapAble={isSwapAble}
							showPlayerValueInsteadOfPoints={showPlayerValueInsteadOfPoints}
							onSwap={onSwap}
							swapPlayerId={swapPlayerId}
							swappedFrom={swappedFrom}
							benchPlayer={true}
							onPlaceholderClick={onPlaceholderClick}
							actionLessPlayerIds={actionLessPlayerIds}
							{...sportSpecificProps}
						/>
					})}
				</div>
			</SubstitutesStyle>
		);
	}
}

export default Substitutes;