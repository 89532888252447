import React, {Component} from "react";
import {connect} from 'react-redux';
import {Dispatch} from 'redux';

import * as userActions from '../../actions/user'
import * as playersActions from '../../actions/players'
import {StoreState, ApplicationState, PlayersState, ClubsState, Player} from './../../types';
import PlayerPortrait from '../../components/PlayerPortrait/PlayerPortrait';
import {ContainerStyle, SelectGroupStyle, BasicTableStyle} from './../../components/PlayerList/PlayerListStyle';
import Link from './../../components/UI/Link/Link';
import {Icon} from 'antd';

import {Element, scroller} from 'react-scroll'

import {
	Layout,
	Title,
	TitleType,
	PlayerList,
	PlayerType,
	Block,
	Button,
	Row,
	Col
} from './../../components';

export interface Props {
	application: ApplicationState;
	players: PlayersState;
	clubs: ClubsState;
	match: any;
}

export interface State {

}


class Race extends Component<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {
		};
	};

	render() {

		return (
			<Layout>
				<Row>
				<Col md={24} sm={24} xs={24}>
						<Block>
                            <Title type={TitleType.h2}>Koersprofiel</Title>
                            <img src={`https://goudenspelen.s3-eu-west-1.amazonaws.com/profiles/${this.props.match.params.id}.jpg`} style={{maxWidth: '100%'}}/>
						</Block>
						
					</Col>
				</Row>
			</Layout>
		);
	}
}

export function mapDispatchToProps(dispatch: Dispatch<userActions.UserAction>) {
	return {}
}

export function mapStateToProps({application, players, clubs}: StoreState.All) {
	return {
		application,
		players,
		clubs
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Race);