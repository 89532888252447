import styled from "styled-components";
import {Tabs} from 'antd';

import 'antd/lib/tabs/style';

export const TabsStyle = styled(Tabs)`
	&.ant-tabs {
		.ant-tabs-nav {
			width: 100%;
			
			.ant-tabs-tab {
				padding-left: 0px;
				padding-right: 0px;
				color: #FFF;
			}
		}
	}
`;