import styled, {keyframes} from "../../styles/styled-components";
import {mediaQueries, sizes} from "../../styles/media-queries";

const RegisterStyle = styled.footer`
	label {
        line-height: 20px;
	}

	.register-form {
		@media ${mediaQueries.tablet} {
			padding: 20px 40px;		
		}
	}
` as any;

export {
	RegisterStyle,
};