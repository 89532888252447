import styled from 'styled-components';
import { Modal} from 'antd';

import 'antd/lib/modal/style';

const PlayerModalStyle = styled(Modal)`
	.ant-modal-content {
		border-radius: 0px;
		max-width: 375px;
	
		.ant-modal-header {
			background-color: ${props => props.theme.primaryColor};
			border-bottom: 0px;
			border-radius: 0px;
			padding: 5px;

			.ant-modal-title {
				color: white;
				p {
					margin: 0px;
				}

				.custom-title-container {
					text-align: right;

					.anticon {
						margin-top: 5px;
						margin-right: 5px;
						font-size: 18px;
					}
				}
			}
		}

		.ant-modal-body {
			p {
				margin-bottom: 0;
			}

			.surname, .forename {
				font-size: 18px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 150px;
			}

			.club, .position {
				font-size: 13px;
			}

			.action {
				margin-top: 15px;
				text-align: center;
				cursor: pointer;

				.anticon {
					font-size: 35px;
    			display: block;
				}
			}

			.points {
				position: absolute;
				right: 5%;
				top: 0px;
				text-align: center;

				.value {
					font-size: 25px;
				}

				.label {
					display: block;
				}
			}

			.player-header {
				margin: 0px;
			}
		}

		.ant-modal-footer {
			display: none;
		}
	}
`;

const PointsOverViewTable = styled.table`
	width: 100%;

	thead {
		background: ${props => props.theme.primaryContrast};
		color: #fff;
	}

	tr {
		th, td {
			padding: 5px;

			&:nth-child(3) {
				text-align: right;
			}
		}
	}
`;

export {
	PlayerModalStyle,
	PointsOverViewTable
};
