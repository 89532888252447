import React, { Component } from "react";
import { H1Style, H2Style, H3Style, H4Style } from "./TitleStyle";

export enum TitleType {
	h1 = "h1",
	h2 = "h2",
	h3 = "h3",
	h4 = "h4",
}

export interface TitleProps {
	type: TitleType;
	children: string;
}

class Title extends Component<TitleProps> {
	render() {
		const { type, children } = this.props;

		return (
			<React.Fragment>
				{type === TitleType.h1 && <H1Style>{children}</H1Style>}
				{type === TitleType.h2 && <H2Style>{children}</H2Style>}
				{type === TitleType.h3 && <H3Style>{children}</H3Style>}
				{type === TitleType.h4 && <H4Style>{children}</H4Style>}
			</React.Fragment>
		);
	}
}

export default Title;