// User
export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
export type SET_PROFILE_DATA = typeof SET_PROFILE_DATA;
export const LOG_OUT = 'LOG_OUT';
export type LOG_OUT = typeof LOG_OUT;
export const USER_TEAMS_LOADING_STARTED = 'USER_TEAMS_LOADING_STARTED';
export type USER_TEAMS_LOADING_STARTED = typeof USER_TEAMS_LOADING_STARTED;
export const USER_TEAMS_FETCHED_WITH_SUCCESS = 'USER_TEAMS_FETCHED_WITH_SUCCESS';
export type USER_TEAMS_FETCHED_WITH_SUCCESS = typeof USER_TEAMS_FETCHED_WITH_SUCCESS;
export const USER_TEAMS_FETCH_FAILED = 'USER_TEAMS_FETCH_FAILED';
export type USER_TEAMS_FETCH_FAILED = typeof USER_TEAMS_FETCH_FAILED;
export const USER_LEAGUES_LOADING_STARTED = 'USER_LEAGUES_LOADING_STARTED';
export type USER_LEAGUES_LOADING_STARTED = typeof USER_LEAGUES_LOADING_STARTED;
export const USER_LEAGUES_FETCHED_WITH_SUCCESS = 'USER_LEAGUES_FETCHED_WITH_SUCCESS';
export type USER_LEAGUES_FETCHED_WITH_SUCCESS = typeof USER_LEAGUES_FETCHED_WITH_SUCCESS;
export const USER_LEAGUES_FETCH_FAILED = 'USER_LEAGUES_FETCH_FAILED';
export type USER_LEAGUES_FETCH_FAILED = typeof USER_LEAGUES_FETCH_FAILED;
export const USER_TEAM_SET_TEAM_NAME = 'USER_TEAM_SET_TEAM_NAME';
export type USER_TEAM_SET_TEAM_NAME = typeof USER_TEAM_SET_TEAM_NAME;

// Players
export const PLAYERS_LOADING_STARTED = 'PLAYERS_LOADING_STARTED';
export type PLAYERS_LOADING_STARTED = typeof PLAYERS_LOADING_STARTED;
export const PLAYERS_FETCHED_WITH_SUCCESS = 'PLAYERS_FETCHED_WITH_SUCCESS';
export type PLAYERS_FETCHED_WITH_SUCCESS = typeof PLAYERS_FETCHED_WITH_SUCCESS;
export const PLAYERS_FETCH_FAILED = 'PLAYERS_FETCH_FAILED';
export type PLAYERS_FETCH_FAILED = typeof PLAYERS_FETCH_FAILED;

// Clubs
export const CLUBS_LOADING_STARTED = 'CLUBS_LOADING_STARTED';
export type CLUBS_LOADING_STARTED = typeof CLUBS_LOADING_STARTED;
export const CLUBS_FETCHED_WITH_SUCCESS = 'CLUBS_FETCHED_WITH_SUCCESS';
export type CLUBS_FETCHED_WITH_SUCCESS = typeof CLUBS_FETCHED_WITH_SUCCESS;
export const CLUBS_FETCH_FAILED = 'CLUBS_FETCH_FAILED';
export type CLUBS_FETCH_FAILED = typeof CLUBS_FETCH_FAILED;

// Matches
export const MATCHES_INFO_LOADING_STARTED = 'MATCHES_INFO_LOADING_STARTED';
export type MATCHES_INFO_LOADING_STARTED = typeof MATCHES_INFO_LOADING_STARTED;
export const MATCHES_INFO_FETCHED_WITH_SUCCESS = 'MATCHES_INFO_FETCHED_WITH_SUCCESS';
export type MATCHES_INFO_FETCHED_WITH_SUCCESS = typeof MATCHES_INFO_FETCHED_WITH_SUCCESS;
export const MATCHES_INFO_FETCH_FAILED = 'MATCHES_INFO_FETCH_FAILED';
export type MATCHES_INFO_FETCH_FAILED = typeof MATCHES_INFO_FETCH_FAILED;
export const MATCHES_FETCHED_WITH_SUCCESS = 'MATCHES_FETCHED_WITH_SUCCESS';
export type MATCHES_FETCHED_WITH_SUCCESS = typeof MATCHES_FETCHED_WITH_SUCCESS;
