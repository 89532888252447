import {CLUBS_LOADING_STARTED, CLUBS_FETCH_FAILED, CLUBS_FETCHED_WITH_SUCCESS} from './actions-types';
import {Club} from './../types'
import http from './../lib/http';
import {Dispatch} from "redux";
import {clubs} from '../cache';

export interface LoadClubs {
	type: CLUBS_LOADING_STARTED
}

export interface ClubsFetchedWithSuccess {
	type: CLUBS_FETCHED_WITH_SUCCESS;
	data: Club[];
}

export interface ClubsFetchFailed {
	type: CLUBS_FETCH_FAILED;
}

export type ClubsAction = LoadClubs | ClubsFetchedWithSuccess | ClubsFetchFailed ;

export function loadClubs(): LoadClubs {
	return {
		type: CLUBS_LOADING_STARTED
	}
}

export function clubsFetchedWithSuccess(data: Club[]): ClubsFetchedWithSuccess {
	return {
		type: CLUBS_FETCHED_WITH_SUCCESS,
		data
	}
}

export function clubsFetchFailed(): ClubsFetchFailed {
	return {
		type: CLUBS_FETCH_FAILED
	}
}

export function fetchClubs(competitionFeed: string, seasonId: number) {
	return function (dispatch: Dispatch<ClubsAction>) {
		dispatch(loadClubs());
		//dispatch(clubsFetchedWithSuccess(clubs));

		return http.get(`clubs`, {competitionFeed, seasonId})
			.then((result: any) => {
				dispatch(clubsFetchedWithSuccess(result.clubs));
			})
			.catch((error: any) => {
				dispatch(clubsFetchFailed());
			});
	};
}
