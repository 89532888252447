import React, {Component} from "react";
import {Row, Col} from 'antd';
import {Redirect} from "react-router-dom";
import qs from 'query-string';
import * as userActions from '../../actions/user'
import config from './../../config';
import lockr from 'lockr';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {pick} from 'lodash';
import {StoreState, UserState, ApplicationState} from './../../types';

export interface Props {
	location: any,
	onSetProfileData: any
	fetchUserTeams: any;
	user: UserState;
	application: ApplicationState;
}

class VrtCallback extends Component<Props> {
	state = {
		redirectToHome: false,
	};

	componentDidMount() {
		const params = qs.parse(this.props.location.search);
		const code = params.code;
		const state = params.state;
		const authState = lockr.get('authState');
		const userAgent = (navigator && navigator.userAgent) || '';

		if (code && state && state === authState) {
			userActions.vrtLogin(code as string, config.VRT.redirectURI, state as string, userAgent as string)
				.then((result: any) => {
					const user = pick(result.user, ['id', 'firstName', 'lastName']);
					lockr.set('token', result.token);
					lockr.set('user', user);
					lockr.rm('authState');
					this.props.onSetProfileData(user);
					this.props.fetchUserTeams(this.props.application.competition.competitionFeed);
				})
				.catch(error => {
					this.setState({redirectToHome: true})
				})
		} else {
			lockr.rm('authState');
			this.setState({redirectToHome: true})
		}
	};

	render() {
		return (
			<Row>
				{this.state.redirectToHome && <Redirect
					to={{pathname: `/home`}}
				/>}

				{this.props.user.authenticated && this.props.user.fetched && <Redirect
					to={{pathname: `/new`}}
				/>}
			</Row>
		);
	}
}

export const mapDispatchToProps = {
	onSetProfileData: (data: any) => (dispatch: any) => dispatch(userActions.setProfileData(data)),
	fetchUserTeams: userActions.fetchTeams
};

export function mapStateToProps({user, application, matches}: StoreState.All) {
	return {
		user,
		application
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(VrtCallback);

