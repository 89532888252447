import React, {Component} from "react";
import {SelectStyle, OptionStyle} from "./SelectStyle";
import {SelectProps} from "antd/lib/select";

const dropDownStyle = {
	backgroundColor: '#F0F3F8',
	borderRadius: '0px',
	overflow: 'hidden',
	boxShadow: 'none'
};

const dropDownListItemStyle = {
	backgroundColor: '#F0F3F8'
}

interface Props {
	values: any[];
	keyProperty: string;
	textProperty: string;
	block?: boolean;
}

export type MySelectProps = SelectProps & Props;

export default class Select extends Component<MySelectProps> {
	render() {
		const {values, keyProperty, textProperty, onChange, ...rest} = this.props;

		return (
			<SelectStyle
				{...rest}
				dropdownStyle={dropDownStyle}
				onChange={onChange as any}>
				{values.map(value => (
					<OptionStyle
						key={value[keyProperty]}
						value={value[keyProperty]}
						style={dropDownListItemStyle}>
						{value[textProperty]}
					</OptionStyle>
				))}
			</SelectStyle>
		);
	}
};
