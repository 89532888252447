export default {
	API_URL: process.env.REACT_APP_API_URL,
	HASH_SALT: 'klass',
	EXPERTS_LEAGUE_ID: 318,
	BUGSNAG_KEY: process.env.REACT_APP_BUGSNAG_KEY || '',
	LOCKR_PREFIX: process.env.REACT_APP_LOCKR_PREFIX,
	COMPETITION_CONFIG: process.env.REACT_APP_COMPETITION_CONFIG && process.env.REACT_APP_COMPETITION_CONFIG.length
		? JSON.parse(process.env.REACT_APP_COMPETITION_CONFIG) : {},
	VRT: process.env.REACT_APP_VRT && process.env.REACT_APP_VRT.length
		? JSON.parse(process.env.REACT_APP_VRT) : {},
	FB_APP_ID: process.env.REACT_APP_FB_APP_ID
};
