import styled from "../../styles/styled-components";
import { mediaQueries } from "../../styles/media-queries";
import { H2, H1 } from "../../styles/global";

const UserProfileStyles = styled.div`
	${H2} {
		text-align: center;
		margin: 40px 0;
	}

	.ant-form-item {
		margin-bottom: 0;
	}
`;

export {
	UserProfileStyles,
};