import styled, {keyframes} from "../../styles/styled-components";
import {mediaQueries} from "../../styles/media-queries";

const Block = styled.div`	
	
	
	@media ${mediaQueries.mobileL} {
		margin: 0;
		padding: 20px;
	}
	
	@media ${mediaQueries.mobileS} {
	}
	
	@media (max-width: 425px) {
		padding-top: 20px;
	}
	
`;

export {
	Block
}