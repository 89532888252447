import styled from "../../styles/styled-components";
import { mediaQueries, sizes } from "../../styles/media-queries";

const MyTeamsStyles = styled.div`
	margin: 20px 0;
	
	.sidebar {
		display: none;

		@media (min-width: 847px) {
			display: block;
		}
	}

	.content {
		@media (max-width: ${sizes.tabletL}px) {
			width: 100%;
		}
	}
`;

export {
	MyTeamsStyles,
};