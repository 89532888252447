import styled from '../../../styles/styled-components';

const List = styled.ul`
	padding: 0;
	list-style: none;
	color: black;
`;

const ListItem = styled.li`

`;

export {
	List,
	ListItem
};