import styled from "../../styles/styled-components";
import { mediaQueries } from "../../styles/media-queries";
import { H2, H1 } from "../../styles/global";

const MatchStyles = styled.div`
	.left, .right {
		@media (max-width: 1024px) {
			width: 100%;
		}
	}
`;

export {
	MatchStyles
};