import styled from 'styled-components';
import { Layout } from 'antd';

import 'antd/lib/layout/style';
import { mediaQueries } from '../../../styles/media-queries';

export const LayoutStyle = styled(Layout)`
    &.ant-layout {
		max-width: 1400px;
		margin: 0 auto;
		background-color: transparent;
		
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			max-width: none;
		}
    }
`;
