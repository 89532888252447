import React from 'react';

import { DatePickerStyle } from './DatePickerStyle';
import { DatePickerProps } from 'antd/lib/date-picker/interface';

type MyDatePickerProps = DatePickerProps
const DatePicker = (props: MyDatePickerProps) => {
	const { ...rest } = props;
	return (<DatePickerStyle {...rest} />);
}

export default DatePicker;
