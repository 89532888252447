import React, { Component } from "react";
import { Icon } from 'antd';
import { Layout } from "./../../components";
import Link from '../UI/Link/Link';

import { FooterStyle } from "./FooterStyle";

type FooterProps = {
}

class Footer extends Component<FooterProps> {
	render() {
		return (
			<FooterStyle>
				<ul>
					<li><Link type={'external'} href={'https://www.dpgmedia.be/nl/privacy'}> <Icon type="info-circle" /> Privacybeleid</Link></li>
					<li><Link type={'external'} href={'https://www.dpgmedia.be/nl/gebruiksvoorwaarden'}> <Icon type="info-circle" /> Gebruikersvoorwaarden</Link></li>
					<li><Link type={'external'} href={'https://www.dpgmedia.be/nl/cookiebeleid'}> <Icon type="info-circle" /> Cookiebeleid</Link></li>
				</ul>
				<div style={{margin: '0 auto', textAlign: 'center', 'color': '#fff'}}>&copy; DPG Media nv. – Mediaplein 1, 2018 Antwerpen – RPR Antwerpen nr. 0432.306.234</div>
			</FooterStyle>
		);
	}
}

export default Footer;