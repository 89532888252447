import {MatchesAction} from '../actions/matches';
import {MatchesState, Week, DeadlineInfo, Match} from '../types/index';
import {
	MATCHES_INFO_FETCH_FAILED,
	MATCHES_INFO_FETCHED_WITH_SUCCESS,
	MATCHES_INFO_LOADING_STARTED,
	MATCHES_FETCHED_WITH_SUCCESS
} from '../actions/actions-types';


const emptyWeeksList: Week[] = [];
const emptyDataList: Match[] = [];
const defaultDeadlineInfo: DeadlineInfo = {
	displayWeek: null,
	deadlineWeek: null,
	deadlineDate: null
};

const defaultState = {
	isFetching: false,
	loaded: false,
	data: emptyDataList,
	info: defaultDeadlineInfo,
	weeks: emptyWeeksList
};

export function matchesReducer(state: MatchesState = defaultState, action: MatchesAction) {
	switch (action.type) {
		case MATCHES_INFO_LOADING_STARTED:
			return {...state, isFetching: true, loaded: false};
		case MATCHES_INFO_FETCH_FAILED:
			return {...state, isFetching: false, loaded: true};
		case MATCHES_INFO_FETCHED_WITH_SUCCESS:
			return {...state, isFetching: false, weeks: action.weeks, info: action.info, loaded: true};
		case MATCHES_FETCHED_WITH_SUCCESS:
			return {...state, isFetching: false, data: action.matches, loaded: true};
	}
	return state;
}