import styled from 'styled-components';
import { DatePicker } from 'antd';

import 'antd/lib/date-picker/style';

const antInputStyle = `
	background-color: #f0f3f8;
	border: 1px solid #d9d9d9;
	border-radius: 0;
	padding: 20px 15px;
	margin: 10px auto;

	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		height: 50px;
		padding-top: 15px;
	}

	&:disabled {
		color: rgba(0, 0, 0, 0.25);
		background: #F9F9F9;
		border: none;
	}

	&.ant-input-lg {
		padding-left: 20px;
		height: 40px;
	}

	&.ant-input-sm {
		padding-left: 10px;
		height: 32px;
	}
`;

export const DatePickerStyle = styled(DatePicker)`
		&.ant-calendar-picker {
			width: 100%;

			&:hover {
				.ant-input {
					border-color: #d9d9d9 !important;
				}
			}
	
			&:focus {
				.ant-input {
					border-color: #d9d9d9;
					box-shadow: none;
					outline: none;
				}
			}

			.ant-input {
				${antInputStyle}
			}
		}
`;