import styled from "./styled-components";
import { mediaQueries } from "./media-queries";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
	body {
		color: #FFFFFF;
		::selection {
			color: black;
		}
		::-moz-selection {
			color: black;
		}
	}

	.vrt-site-footer {
		margin-top: 0;
	}

	.cookie-cancel-button {
		font-size: 10px;
		color: #333 !important;
    	background: none !important;
    	text-decoration: none !important;
	}
`;

const PageStyle = styled.div`
	padding: 10px 0;

	@media ${mediaQueries.tablet} {
		padding: 20px 0;
	}
`;

const H1 = styled.h2`
	font-size: 34px;
	font-weight: bold;
	color: #FFFFFF !important;
`;

const H2 = styled.h2`
	font-size: 32px;
	font-weight: bold;
	color: #FFFFFF !important;
`;

const H3 = styled.h2`
	font-size: 28px;
	margin: 5px;
	color: #FFFFFF !important;
`;

export {
	GlobalStyle,
	PageStyle,
	H1,
	H2,
	H3
};
