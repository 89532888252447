import React, { Component } from 'react';
import { Icon } from 'antd';
import {
	ContainerStyle,
	SelectGroupStyle,
	TableStyle,
	PlayerStyle,
} from './PlayerStatsListStyle';
import Button from '../UI/Button/Button';
import Input from '../UI/Input/Input';
import PlayerAvatar from '../Player/Player';
import Select from '../UI/Select/Select';
import Link from '../UI/Link/Link';
import { PlayerType } from '../Player/Player';
import { Club, Player, OnPickType, IsPickAbleType, UserState, MatchesState, ApplicationState } from '../../types';
import { roundNextHalf, getPlayerPositionHexColor } from '../../lib/helpers';
import { BikeSvg, FootballSvg, EuroSvg, FootballFieldSvg } from '../../styles/custom-icons';
import * as playersActions from '../../actions/players'


const FootballIcon = (props: any) => <Icon component={FootballSvg} {...props} />;
const EuroIcon = (props: any) => <Icon component={EuroSvg} {...props} />;
const FootballFieldIcon = (props: any) => <Icon component={FootballFieldSvg} {...props} />;

export interface PlayerStatsListProps {
	size: number;
	action: boolean;
	isLoading?: boolean;
	hidePositions?: boolean;
	onSelect?: any;
	showHeader?: boolean;
	playerType: PlayerType;
	clubs: any[];
	matches: MatchesState;
	activePositionFilter?: number,
	setActivePositionFilter?: any,
	activeStatsFilter?: number,
	setActiveStatsFilter?: any,
	assetsCdn: string;
	actionLabel?: string;
	playerTax?: number | undefined;
	t: any;
	application: ApplicationState;
	user: UserState;
}

export interface PlayerStatsListState {
	filters: any;
	pagination: any;
	stats: any[];
	sorter: string;
}

class PlayerStatsList extends Component<PlayerStatsListProps, PlayerStatsListState> {

	constructor(props: PlayerStatsListProps) {
		super(props);

		this.state = {
			filters: {
				name: '',
				playerValue: 100,
				clubId: -1,
				weekId: -1,
				positionId: props.activePositionFilter || -1,
				stat: props.activeStatsFilter || 0,
			},
			pagination: {
				pageNumber: 1,
				pageRecords: props.size,
				totalRecords: null
			},
			stats: [],
			sorter: 'total DESC' //todo default to value gameweek 0
		};
	}

	componentDidMount() {
		this.fetchPlayersStats();
	}

	__getFilterValue(filterName: string) {
		return this.state.filters[filterName] !== -1 ? this.state.filters[filterName] : null;
	};

	async fetchPlayersStats() {
		return playersActions.getPlayersAggregatedStats(
			this.props.application.competition.competitionFeed,
			this.props.application.competition.seasonId,
			this.state.pagination.pageNumber,
			this.state.pagination.pageRecords,
			this.state.sorter,
			this.state.filters.name.length ? this.state.filters.name : null,
			this.__getFilterValue('weekId'),
			this.__getFilterValue('clubId'),
			this.__getFilterValue('positionId'),
			this.__getFilterValue('playerValue')
		)
			.then(result => {
				const pagination = { ...this.state.pagination, totalRecords: result.totalRecords };
				this.setState({ pagination, stats: result.data });
			});
	};

	componentDidUpdate(prevProps: PlayerStatsListProps, prevState: PlayerStatsListState) {
		if (this.props.activePositionFilter !== prevProps.activePositionFilter) {
			const filters = { ...this.state.filters, positionId: this.props.activePositionFilter }
			this.setState({ filters });
		}

		if (this.props.activeStatsFilter !== prevProps.activeStatsFilter) {
			const filters = { ...this.state.filters, stat: this.props.activeStatsFilter }
			this.setState({ filters });
		}

		const sorterChanged = this.state.sorter !== prevState.sorter;
		const filtersChanged = this.state.filters.name !== prevState.filters.name
			|| this.state.filters.playerValue !== prevState.filters.playerValue
			|| this.state.filters.clubId !== prevState.filters.clubId
			|| this.state.filters.weekId !== prevState.filters.weekId
			|| this.state.filters.positionId !== prevState.filters.positionId
			|| this.state.filters.stat !== prevState.filters.stat;
		const paginationChanged = this.state.pagination.pageNumber !== prevState.pagination.pageNumber;

		if (sorterChanged || filtersChanged || paginationChanged) {
			this.fetchPlayersStats();
		}
	}

	onFilterChange = (name: string, value: string | number) => {
		const filters: any = Object.assign({}, this.state.filters, {
			[name]: value,
		});

		if (this.props.activePositionFilter && this.props.setActivePositionFilter && name === 'positionId') {
			this.props.setActivePositionFilter(value);
		} else {
			this.setState({ filters });
		}
	};

	onStatChange = (name: string, value: string | number) => {
		const filters: any = Object.assign({}, this.state.filters, {
			[name]: value,
		});

		if (this.props.activeStatsFilter && this.props.setActiveStatsFilter && name === 'positionId') {
			this.props.setActiveStatsFilter(value);
		} else {
			this.setState({ filters });
		}
	};

	handleTableChange = (pagination: any, filters: any, sorter: any) => {
		const newPagination = { ...this.state.pagination, pageNumber: pagination.current };
		const sorterString = sorter && sorter.columnKey ? `${sorter.columnKey} ${sorter.order === 'descend' ? 'DESC' : 'ASC'}` : 'total DESC';  //todo default to value gameweek 0
		this.setState({ pagination: newPagination, sorter: sorterString });
	};

	render() {
		const {
			action,
			size,
			showHeader,
			clubs,
			hidePositions,
			playerType,
			isLoading,
			assetsCdn,
			actionLabel,
			playerTax,
			t
		} = this.props;

		const { stats } = this.state;

		const clubsList = [{
			id: -1,
			name: <span className={'prefixed-label'}> <FootballIcon style={{ marginRight: 5 }} /> {'Alle ploegen'} </span>
		}]
			.concat(clubs);

		const budgets = [
			{ text: <span className={'prefixed-label'}> <EuroIcon /> {'Onbeperkt budget'} </span>, value: 100 },
			{ text: 'Minder dan 20 miljoen', value: 19 },
			{ text: 'Minder dan 18 miljoen', value: 17 },
			{ text: 'Minder dan 16 miljoen', value: 15 },
			{ text: 'Minder dan 14 miljoen', value: 13 },
			{ text: 'Minder dan 12 miljoen', value: 11 },
			{ text: 'Minder dan 10 miljoen', value: 9 },
			{ text: 'Minder dan 8 miljoen', value: 7 },
			{ text: 'Minder dan 6 miljoen', value: 5 },
			{ text: 'Minder dan 4 miljoen', value: 3 },
		];

		const positions = [
			{ id: -1, name: <span className={'prefixed-label'}> <FootballFieldIcon /> {'Alle posities'} </span> },
			{ id: 1, name: 'Doelman' },
			{ id: 2, name: 'Verdediger' },
			{ id: 3, name: 'Middenvelder' },
			{ id: 4, name: 'Aanvaller' },
		];

		const statsFilter = [
			{ id: 0, text: <span className={'prefixed-label'}> {'Aanvallend'} </span>, value: [{value: 'statGoals', label: 'D'}, {value: 'statAssists', label: 'A'}] },
			{ id: 1, text: `${'Verdedigend'}`, value: [{value: 'statConceeded', label: 'Tegen'}, {value: 'statCleanSheet', label: 'Nul'}] },
			{ id: 2, text: `${'Kaarten'}`, value: [{value: 'statYellow', label: 'G'}, {value: 'statRed', label: 'R'}] },
			{ id: 3, text: `${'Speeltijd'}`, value: [{value: 'statTimePlayed', label: 'Tijd'}, {value: 'statMatchPlayed', label: 'W'}] },
			{ id: 4, text: `${'Populariteit'}`, value: [{value: 'statPicked', label: '%IN'}, {value: 'statCapped', label: '%CAP'}] },//points per min //selection %// pickorder//
		];

		let weeks: any = this.props.matches.weeks.map((week: any) => {
			return {
				id: week.weekId,
				name: `${'Speeldag'} ${week.weekId}`,
			};
		});

		weeks.unshift({ id: -1, name: <span className={'prefixed-label'}> <Icon type="calendar" /> {'Alle Speeldagen'} </span> });

		const columns = [
			{
				key: 'rank',
				title: '#',
				dataIndex: 'generalInfo',
				width: 50,
				fixed: 'left',
			
				render: (text: string, record: any, index: number) => {
					const rank = ((this.state.pagination.pageNumber - 1) * this.state.pagination.pageRecords) + index + 1;

					return (
						<React.Fragment >
							<span> {rank} </span>
						</React.Fragment>
					);
				},
			},
			{
				key: 'name',
				sorter: true,
				title: 'Speler',
				dataIndex: 'generalInfo',
				width: 150,
				fixed: 'left',
				render: (text: string, record: any) => {
					return (
						<React.Fragment>
							<span> {record.generalInfo.short || record.generalInfo.name} </span>
						</React.Fragment>
					);
				},
			}, 
			{
				key: 'clubName',
				title: 'Club',
				sorter: true,
				dataIndex: 'clubName',
				with: 75,
				render: (text: string, record: any) => {
					return (
						<React.Fragment>
							{clubs.length > 0 && clubs.filter((club) => club.id === record.clubId)[0].short}
						</React.Fragment>
					);
				},
			},
			{
				key: 'positionId',
				title: 'Pos.',
				sorter: true,
				dataIndex: 'positionId',
				width: 75,
				render: (text: string, record: any) => {
					const position = positions.find((item: any) => item.id === record.positionId);

					return (
						<React.Fragment>
							<span> {position && position.name || ''} </span>
						</React.Fragment>
					); 
				},
			},
			{
				key: 'total',
				title: 'Ptn',
				sorter: true,
				dataIndex: 'total',
				width: 100,
				render: (text: string, record: any) => {
					return (
						<React.Fragment>
							<span> {record.total} </span>
						</React.Fragment>
					);
				},
			},
			{
				key: statsFilter[this.state.filters.stat].value[0].value,
				title: statsFilter[this.state.filters.stat].value[0].label,
				sorter: true,
				dataIndex: statsFilter[this.state.filters.stat].value[0].value,
				width: 100,
				render: (text: string, record: any) => {
					return (
						<React.Fragment>
							<span> {record[statsFilter[this.state.filters.stat].value[0].value]} </span>
						</React.Fragment>
					);
				},
			},
			{
				key: statsFilter[this.state.filters.stat].value[1].value,
				title: statsFilter[this.state.filters.stat].value[1].label,
				sorter: true,
				dataIndex: statsFilter[this.state.filters.stat].value[1].value,
				width: 100,
				render: (text: string, record: any) => {
					return (
						<React.Fragment>
							<span> {record[statsFilter[this.state.filters.stat].value[1].value]} </span>
						</React.Fragment>
					);
				},
			},
			{
				key: 'playerValue',
				title: '€',
				sorter: true,
				dataIndex: 'playerValue',
				width: 100,
				render: (text: string, record: any) => {
					return (
						<React.Fragment>
							<span> €{record.playerValue}M </span>
						</React.Fragment>
					);
				},
			}
		];

		let tableEventHandler: any = () => { };

		if (this.props.onSelect) {
			tableEventHandler = (player: any) => ({
				onClick: (event: any) => {
					this.props.onSelect(player);
				},
			});
		}

		const pagination = {
			current: this.state.pagination.pageNumber,
			total: this.state.pagination.totalRecords,
			pageSize: this.state.pagination.pageSize,
		};

		return (
			<ContainerStyle>
				{
					<Input
						prefix={<Icon type="search" />}
						type="text"
						placeholder={'Zoeken op naam…'}
						name="name"
						onChange={(event: any) =>
							this.onFilterChange(event.target.name, event.target.value)
						}
						style={{ margin: 0 }}
					/>
				}
				<SelectGroupStyle>
					<Select
						block
						values={clubsList}
						style={{ marginLeft: 0 }}
						keyProperty={'id'}
						onSelect={(value: any) => this.onFilterChange('clubId', value)}
						textProperty={'name'}
						placeholder={clubsList && clubsList[0].name}
					/>
					<Select
						block
						value={this.state.filters.position}
						keyProperty={'id'}
						textProperty={'name'}
						onSelect={(value: any) => this.onFilterChange('positionId', value)}
						values={positions}
						placeholder={positions[0].name}
					/>
					<Select
						block
						value={this.state.filters.weekId}
						keyProperty={'id'}
						textProperty={'name'}
						onSelect={(value: any) => this.onFilterChange('weekId', value)}
						values={weeks}
						placeholder={weeks[0].name}
					/>
					<Select
						block
						values={budgets}
						keyProperty={'value'}
						onSelect={(value: any) => this.onFilterChange('playerValue', value)}
						textProperty={'text'}
						placeholder={budgets[0].text}
					/>
					<Select
						block
						style={{ marginRight: 0 }}
						value={this.state.filters.stat}
						keyProperty={'id'}
						textProperty={'text'}
						onSelect={(value: any) => this.onStatChange('stat', value)}
						values={statsFilter}
						placeholder={statsFilter[0].text}
					/>
				</SelectGroupStyle>

				<TableStyle
					columns={columns}
					dataSource={stats}
					scroll={{ x: 'auto' }}
					showHeader={showHeader}
					locale={{ emptyText: 'Geen spelers gevonden.' }}
					loading={isLoading}
					pagination={pagination}
					onChange={this.handleTableChange}
					onRow={tableEventHandler}
					rowKey="id"
					rowClassName={(record: object, index: number) =>
						`${index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even'} ${
						this.props.onSelect ? 'cursor-pointer' : ''
						}`
					}
				/>
			</ContainerStyle>
		);
	}
}

export default PlayerStatsList;
