import styled from "../../styles/styled-components";
import { mediaQueries } from "../../styles/media-queries";
import { H2, H1 } from "../../styles/global";

const ForgotStyles = styled.div`
	${H2} {
		text-align: center;
		margin: 40px 0;
	}

	.forgot-form {
		@media ${mediaQueries.tablet} {
			padding: 20px 40px;		
		}
	}
`;

export {
	ForgotStyles,
};