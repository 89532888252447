import styled from "../../styles/styled-components";
import { mediaQueries } from "../../styles/media-queries";

const DashboardStyle = styled.div`
	th {
		&.paid-column {
			@media (max-width: 470px) {
				.ant-table-column-title {
					visibility: hidden;
					font-size: 0px;
					:after {
						visibility: visible;
						font-size: 14px;
						content: 'B';
					}
				}
			}
		}
	}
`;

const ActionsList = styled.ul`
	display: flex;
	list-style: none;
	align-items: stretch;
	justify-content: space-between;

	li {
		flex: 0 1 auto;
		list-style-type: none;
		width: 100%;
		text-align: center;
		cursor: pointer;

		.anticon {
			position: relative;
			top: 5px;
		}

		.label {
				display: none;

				@media ${mediaQueries.tablet} {
					display: inline-block;
					margin-left: 5px;
				}
		}
	}
`;

const EditableTeamNameStyle = styled.div `
	.ant-input {
		max-width: 200px;
	}

	.anticon {
		font-size: 24px;
		position: relative;
    	top: 4px;
    	margin: 2px;
	}

	.anticon-check-circle {
		color: green;
	}
`;

const PaidLabel = styled.div `
	.badge {

		@media (max-width: 470px) {
			margin-left: -8px;
		}
		
		.label {
			@media (max-width: 470px) {
				display: none;
			}
		}

		.anticon {
			position: initial;
			font-size: 22px;
			margin-left: 0px;
		}

		.anticon-check-circle {
			color: green;
		}

		.anticon-exclamation-circle {
			color: red;
		}
	}
`;

const TeamNameWithEditButton = styled.div `
	.team-name {
		overflow: hidden;
		width: 120px; 
		text-overflow: ellipsis;
		display: inline-block; 

		@media (max-width: 470px) {
			width: 80px;
		}
	}

	.anticon {
		color: #F00;
		font-size: 20px;
		margin-left: 5px;
		position: relative;
		top: -5px;
	}
`;

export {
	DashboardStyle,
	ActionsList,
	EditableTeamNameStyle,
	TeamNameWithEditButton,
	PaidLabel
};