import React from 'react';

import { InputStyle, InputPasswordStyle } from './InputStyle';
import { InputProps, PasswordProps } from 'antd/lib/input';

type MyInputProps = InputProps
export const Input = (props: MyInputProps) => {
	const { ...rest } = props;
	return (<InputStyle {...rest} />);
}

type MyInputPasswordProps = InputProps & PasswordProps;
export const InputPassword = (props: MyInputPasswordProps) => {
	const { ...rest } = props;
	return (<InputPasswordStyle {...rest} />);
}

export default Input;
