import styled from "../../styles/styled-components";
import { mediaQueries, sizes } from "../../styles/media-queries";

const SaveLineupButton = styled.div`
	text-align: center;
	margin: 10px 0px 10px 0px;

	@media ${mediaQueries.tabletL} {
		text-align: right;
	}

	.ant-btn {
		width: 100%;

		@media ${mediaQueries.tabletL} {
			max-width: 200px;
		}
	}


`;

export {
	SaveLineupButton,
};