import {
	MATCHES_INFO_LOADING_STARTED,
	MATCHES_INFO_FETCH_FAILED,
	MATCHES_INFO_FETCHED_WITH_SUCCESS,
	MATCHES_FETCHED_WITH_SUCCESS
} from './actions-types';
import { Week, DeadlineInfo, Match } from './../types'
import http from './../lib/http';
import { Dispatch } from "redux";

export interface LoadMatchesInfo {
	type: MATCHES_INFO_LOADING_STARTED
}

export interface MatchesInfoFetchedWithSuccess {
	type: MATCHES_INFO_FETCHED_WITH_SUCCESS;
	weeks: Week[];
	info: DeadlineInfo;
}

export interface MatchesFetchedWithSuccess {
	type: MATCHES_FETCHED_WITH_SUCCESS;
	matches: Match[];
}

export interface MatchesFetchFailed {
	type: MATCHES_INFO_FETCH_FAILED;
}

export type MatchesAction = LoadMatchesInfo
	| MatchesInfoFetchedWithSuccess
	| MatchesFetchFailed
	| MatchesFetchedWithSuccess;

export function loadMatches(): LoadMatchesInfo {
	return {
		type: MATCHES_INFO_LOADING_STARTED
	}
}

export function matchesInfoFetchedWithSuccess(weeks: Week[], info: DeadlineInfo): MatchesInfoFetchedWithSuccess {
	return {
		type: MATCHES_INFO_FETCHED_WITH_SUCCESS,
		weeks,
		info
	}
}

export function matchesFetchedWithSuccess(matches: Match[]): MatchesFetchedWithSuccess {
	return {
		type: MATCHES_FETCHED_WITH_SUCCESS,
		matches,
	}
}

export function matchesFetchFailed(): MatchesFetchFailed {
	return {
		type: MATCHES_INFO_FETCH_FAILED
	}
}

export function fetchMatchesInfo(competitionFeed: string, seasonId: number) {
	return function (dispatch: Dispatch<MatchesAction>) {
		dispatch(loadMatches());

		return http.get(`matches/deadline-info`, {competitionFeed, seasonId})
			.then((result: any) => {
				dispatch(matchesInfoFetchedWithSuccess(result.weeks, result.deadlineInfo));
			})
			.catch((error: any) => {
				dispatch(matchesFetchFailed());
			});
	};
}

export function fetchWeekMatches(competitionFeed: string, seasonId: number, weekId: number) {
	return http.get(`matches`, { competitionFeed, seasonId, weekId });
}

export function getMatchStats(matchId: number) {
	return http.get(`match/${matchId}`, {});
}

export function getRssFeeds(competitionFeed: string, seasonId: number) {
	return http.get(`matches/rss`, { competitionFeed, seasonId });
}

export function fetchMatches(competitionFeed: string, seasonId: number) {
	return function (dispatch: Dispatch<MatchesAction>) {
		dispatch(loadMatches());

		return http.get(`matches`, {competitionFeed, seasonId})
			.then((result: any) => {
				dispatch(matchesFetchedWithSuccess(result.matches));
			})
			.catch((error: any) => {
				dispatch(matchesFetchFailed());
			});
	};
}

export function getPromotions(competitionFeed: string, seasonId: number) {
	return http.get(`promotions`, { competitionFeed, seasonId });
}
