import React, {Component} from "react";
import {FormStyle, FormItemStyle} from "./FormStyle";
import {FormProps, FormItemProps} from "antd/lib/form";

export const Form = (props: any) => {
	const {children, ...rest} = props;

	return (<FormStyle
		{...rest}>
		{children}
	</FormStyle>);
};

export const FormItem = (props: any) => {
	const {children, ...rest} = props;

	return (<FormItemStyle
		{...rest}>
		{children}
	</FormItemStyle>);
};

export default Form;
