import {
	loadLeagues,
	leaguesFetchedWithSuccess,
	leaguesFetchFailed,
	UserAction,
} from './user';

import { Dispatch } from 'redux';
import http from './../lib/http';
import { notification } from 'antd';

export function fetchTeamLeagues(teamId: number) {
	return http.get(`leagues/${teamId}`, {});
}

export function fetchLeagueInfo(
	leagueId: number,
	weekId?: number | null,
	externalTeamIds: number[] = []
) {
	return http.get(
		`league/${leagueId}?_t
		${weekId ? `&weekId=${weekId}` : ''}
		${
			externalTeamIds && externalTeamIds.length
				? `&externalTeamIds=[${externalTeamIds.join(',')}]`
				: ''
		}`,
		{}
	);
}

export function searchLeagues(
	name: string,
	competitionFeed: string,
	seasonId: number
) {
	return http.get(`league/search`, { name, competitionFeed, seasonId });
}

export function searchLeagueId(
	leagueId: number,
	competitionFeed: string,
	seasonId: number
) {	
	return http.get(`league/search`, { leagueId, competitionFeed, seasonId });
}

export function createLeague(
	name: string,
	competitionFeed: string,
	seasonId: number,
	teamId: number
) {
	return http.post(`league/team/${teamId}`, {
		name,
		competitionFeed,
		seasonId,
	});
}

export function joinLeague(leagueId: number, teamId: number) {
	return http.post(`league/${leagueId}/join/${teamId}`, {});
}

export function leaveLeague(leagueId: number, teamId: number) {
	return http.delete(`league/${leagueId}/${teamId}`, {});
}

export function removeLeague(leagueId: number) {
	return http.delete(`league/${leagueId}`, {});
}
