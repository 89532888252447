// const { positionIds } = require("../../api/models/Team");

function FootballPicker(maxPositionsPicks, positionsIds) {

    var result = {
      Goalkeeper: [],
      Defender: [],
      Midfielder: [],
      Forward: [],
      Bench: [],
      Coach: []
    };
  
    function pickAtPosition(positionName, playerId) {
      result[positionName].push(playerId);
    }
  
    function setPositionPickLimit(position, limits) {
      maxPositionsPicks[position] = limits;
    }
  
    function set(starting, bench) {
      result.Goalkeeper = [];
          result.Defender = [];
          result.Midfielder = [];
          result.Forward = [];
      result.Bench = [];
      result.Coach = [];
  
      starting.forEach(function (player) {
        result[getPositionNameById(player.Player.position_id)].push(player.Player.id)
      });
  
      bench.forEach(function (player) {
        result.Bench.push(player.Player.id);
      });
  
      return {
        possibleFormations: getPossibleFormations(),
        result: result
      };
    }
  
    function canPick(player, ignoreBench) {
      return checkAndPick(player, true, ignoreBench);
    }
  
    function checkAndPick(player, justCheck, ignoreBench) {
      var checkedPositions = ['Defender', 'Midfielder', 'Forward'];
      var maxAllowedPlayersForPositions = getMaxAllowedPlayersForPositions(checkedPositions);
      var pickedPositionName = getPositionNameById(player.Player.position_id);
  
      if (result[pickedPositionName].indexOf(player.Player.id) !== -1) {
        return false;
      }
  
      if(!ignoreBench && result.Bench.indexOf(player.Player.id) !== -1) {
        return false;
      }
  
      if (result[pickedPositionName] && (result[pickedPositionName].length < maxAllowedPlayersForPositions[pickedPositionName])) {
        if (!justCheck) {
          pickAtPosition(pickedPositionName, player.Player.id);
        }
        return true;
      } else {
        var benchCount = result['Bench'].length;
        if (benchCount < maxPositionsPicks['Bench'].max) {
          if (!justCheck) {
            pickAtPosition('Bench', player.Player.id);
          }
          return true;
        } else {
          return false;
        }
      }
    }
  
    function isValid() {
      var fieldPlayers = 11;
      var validated = (result.Goalkeeper.length >= maxPositionsPicks.Goalkeeper.min && result.Goalkeeper.length <= maxPositionsPicks.Goalkeeper.max) 
      && (result.Defender.length >= maxPositionsPicks.Defender.min && result.Defender.length <= maxPositionsPicks.Defender.max)
      && (result.Midfielder.length >= maxPositionsPicks.Midfielder.min && result.Midfielder.length <= maxPositionsPicks.Midfielder.max)
      && (result.Forward.length >= maxPositionsPicks.Forward.min && result.Forward.length <= maxPositionsPicks.Forward.max)
      && (result.Bench.length >= maxPositionsPicks.Bench.min && result.Bench.length <= maxPositionsPicks.Bench.max)
      && (result.Coach.length >= maxPositionsPicks.Coach.min && result.Coach.length <= maxPositionsPicks.Coach.max)
      && ((fieldPlayers + result.Bench.length) === (result.Goalkeeper.length + result.Defender.length + result.Midfielder.length + result.Forward.length + result.Bench.length));
  
      return validated;
    }
  
    function getMaxAllowedPlayersForPositions(checkedPositions) {
      // we ignore the goalkeeper since it can be only one (min==max==1) in the field
      // but the other positions may fluctuate from min to max
      var maxPlayersForEachPosition = {
        Goalkeeper: 1,
        Defender: 0,
        Midfielder: 0,
        Forward: 0,
        Coach: 1
      };
  
      var totalFieldPlayers = 10;
  
      checkedPositions.forEach(function (checkingPosition) {
        var pickedAlreadyForPosition = result[checkingPosition].length;
  
        var checkingAgainstPositions = checkedPositions.filter(function (position) {
          return position !== checkingPosition;
        });
  
        var spotsLeft = totalFieldPlayers - pickedAlreadyForPosition;
  
        checkingAgainstPositions.forEach(function (againstPosition) {
          if (result[againstPosition].length < maxPositionsPicks[againstPosition].min) {
            spotsLeft = spotsLeft - maxPositionsPicks[againstPosition].min;
          } else {
            spotsLeft = spotsLeft - result[againstPosition].length;
          }
        });
        if (spotsLeft > 0) {
          var maxForPosition = pickedAlreadyForPosition;
          while (spotsLeft > 0 && maxForPosition < maxPositionsPicks[checkingPosition].max) {
            maxForPosition += 1;
            spotsLeft -= 1;
          }
          maxPlayersForEachPosition[checkingPosition] = maxForPosition;
        } else {
          maxPlayersForEachPosition[checkingPosition] = pickedAlreadyForPosition;
        }
      });
  
      return maxPlayersForEachPosition;
    }
  
    function getCombinations(maxAllowedPlayersForPositions) {
      var combinations = [];
      var defaults = {
        defenderNumber: result.Defender.length > maxPositionsPicks.Defender.min ? result.Defender.length : maxPositionsPicks.Defender.min,
        midfielderNumber: result.Midfielder.length > maxPositionsPicks.Midfielder.min ? result.Midfielder.length : maxPositionsPicks.Midfielder.min,
        forwardNumber: result.Forward.length > maxPositionsPicks.Forward.min ? result.Forward.length : maxPositionsPicks.Forward.min
      };
  
      for (var defenderNumber = defaults.defenderNumber; defenderNumber <= maxAllowedPlayersForPositions.Defender; defenderNumber++) {
        for (var midfielderNumber = defaults.midfielderNumber; midfielderNumber <= maxAllowedPlayersForPositions.Midfielder; midfielderNumber++) {
          for (var forwardNumber = defaults.forwardNumber; forwardNumber <= maxAllowedPlayersForPositions.Forward; forwardNumber++) {
            var combination = defenderNumber + '-' + midfielderNumber + '-' + forwardNumber;
            if ((defenderNumber + midfielderNumber + forwardNumber) === 10) {
              if (combinations.indexOf(combination) === -1) {
                combinations.push(combination);
              }
            }
          }
        }
      }
      return combinations;
    }
  
    function getPossibleFormations() {
      var checkedPositions = ['Defender', 'Midfielder', 'Forward'];
      var maxAllowedPlayersForPositions = getMaxAllowedPlayersForPositions(checkedPositions);
      return getCombinations(maxAllowedPlayersForPositions);
    }
  
    function pick(player) {
      var picked = checkAndPick(player);
  
      return {
        possibleFormations: getPossibleFormations(),
        result: result,
        picked: picked
      };
    }
  
    function getPositionNameById(positionId) {
      return Object.keys(positionsIds)
        .find(function (positionName) {
          return positionsIds[positionName] === positionId
        })
    }
  
    function remove(player) {
      var removeId = player.Player.id;
      var positionId = player.Player.position_id;
      var removed = false;
  
      result[getPositionNameById(positionId)] = result[getPositionNameById(positionId)]
        .filter(removeHandler);
      result.Bench = result.Bench
        .filter(removeHandler);
  
      function removeHandler(playerId) {
        if (playerId === removeId) {
          removed = true;
          return false;
        } else {
          return true;
        }
      }
  
      return {
        possibleFormations: getPossibleFormations(),
        result: result,
        removed: removed
      };
    }
  
    return {
      pick: pick,
      remove: remove,
      set: set,
      isValid: isValid,
      canPick: canPick,
      getPossibleFormations: getPossibleFormations,
      setPositionPickLimit: setPositionPickLimit
    };
  }
  
  exports.default = FootballPicker;