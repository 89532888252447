import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Player, AbstractTeamType } from './../../types';
import {
	Layout, Team, Substitutes, PlayerType, Row, Col, Block,
	AbstractTeam, MatchDaySelector, FootballCalendar, Stats
} from './../../components';
import { Icon } from 'antd';
import { theme } from '../../styles/theme-goudenspelen';

const teamBackground = 'https://goudenspelen.s3-eu-west-1.amazonaws.com/files/lineup-field.png';
import { startingListToPositionsList } from './../../lib/helpers';
import { pick } from 'lodash';
import * as teamsActions from '../../actions/teams'

export interface Props {
	match: any;
	t: any;
}

export interface State {
	notFound: boolean;
	navigateToLeagues: boolean;
	selectedLeagueId: number | null;
}

class TeamContainer extends Component<AbstractTeamType & Props, State> {
	constructor(props: AbstractTeamType & Props) {
		super(props);

		this.state = {
			notFound: false,
			navigateToLeagues: false,
			selectedLeagueId: null,
		}
	};

	componentDidMount() {
		if (this.props.visibleWeekId) {
			this.getTeamInfo(this.props.visibleWeekId);
		} else if (!this.props.matches.info.deadlineWeek && this.props.matches.info.displayWeek) {
			this.getTeamInfo(this.props.matches.info.displayWeek);
		}
	}

	componentDidUpdate(prevProps: AbstractTeamType & Props) {
		if (this.props.visibleWeekId && (this.props.visibleWeekId !== prevProps.visibleWeekId)) {
			this.getTeamInfo(this.props.visibleWeekId);
		}

		if (!this.props.matches.info.deadlineWeek && this.props.matches.info.displayWeek && (this.props.matches.info.displayWeek !== prevProps.matches.info.displayWeek)) {
			this.getTeamInfo(this.props.matches.info.displayWeek);
		}
	}

	parsePlayerPointsValue = (value: string) => {
		try {
			return JSON.parse(value);
		} catch (error) {
			return {};
		}
	};

	getTeamInfo = (weekId: number) => {
		const pointsWeekId = this.props.matches.info.deadlineWeek === weekId ? weekId - 1 : weekId;
		const teamId = this.props.match.params.id;
		const playerProps =
		['id', 'name', 'short', 'positionId', 'clubId', 'value', 'ban', 'injury', 'form', 'forename', 'surname', 'stats'];
		const selectionProps: any[] = ['points'];

		Promise.all([teamsActions.points(teamId, pointsWeekId)])
			.then(([result]) => {
				const weekStat = result.weekStat.find((stat: any) => stat.weekId === pointsWeekId);

				const teamPointsInfo = {
					generalPoints: result.team.points !== null ? result.team.points : '-',
					generalRank: result.team.rank !== null ? result.team.rank : '-',
					visibleWeekPoints: (weekStat && weekStat.points) || '-',
					visibleWeekRank: (weekStat && weekStat.rank) || '-'
				};

				const starting = result.players
					.filter((player: any) => player.selection.positionId === 1)
					.map((player: any) => {
						const playerStats = player.stats && player.stats[0];
						const pointsOverview = playerStats && playerStats.value ? this.parsePlayerPointsValue(playerStats.value) : {};

						const displayWeekMatches = this.props.matches.data
							.filter((match: any) => match.weekId === pointsWeekId && ([match.homeId.id, match.awayId.id].includes(player.clubId)));

						return Object.assign({ inStarting: true, upcomingMatches: displayWeekMatches }, { pointsOverview }, pick(player, playerProps), pick(player.selection, selectionProps))
					});

				const bench = result.players
					.filter((player: any) => player.selection.positionId === 0)
					.map((player: any) => {
						const playerStats = player.stats && player.stats[0];
						const pointsOverview = playerStats && playerStats.value ? this.parsePlayerPointsValue(playerStats.value) : {};

						const displayWeekMatches = this.props.matches.data
							.filter((match: any) => match.weekId === pointsWeekId && ([match.homeId.id, match.awayId.id].includes(player.clubId)));


						return Object.assign({ inStarting: false, upcomingMatches: displayWeekMatches }, { pointsOverview }, pick(player, playerProps), pick(player.selection, selectionProps))
					})
					.sort((firstItem: any, secondItem: any) => {
						if (firstItem.positionId === 1) {
							return -1;
						} else {
							return 0;
						}
					});
				const team = result.team;
				const teamName = result.team.name;

				const captainId = result.players
					.find((player: any) => player.selection.captain === 1)
					.id;

				const budget = result.players
					.reduce((acc: any, player: any) => acc - player.value, this.props.application.competition.budget);

				this.props.loadAllMatches();
				this.props.initTeamState(starting, bench, team, teamName, captainId, budget, undefined, pointsWeekId, teamPointsInfo);
			})
			.catch(error => {
				const teamPointsInfo = {
					generalPoints: '-',
					generalRank: '-',
					visibleWeekPoints: '-',
					visibleWeekRank: '-'
				};

				this.setState({ notFound: true });
				this.props.initTeamState([], [], {}, '', null, 0, undefined, pointsWeekId, teamPointsInfo);
			})
	};

	render() {
		const {
			application, starting, bench, matches, visibleWeekId, initializedExternally,
			teamPointsInfo, captainId
		} = this.props;
		const startingByPositions = startingListToPositionsList(starting, application.competition.lineupPositionRows, 'football');

		const deadlineWeek = matches.info && matches.info.deadlineWeek;
		const displayWeek = matches.info && matches.info.displayWeek
		const match = matches.data.filter(match => match.weekId === visibleWeekId);

		const teamOwner = this.props.team && this.props.user && this.props.user.data && (this.props.team.userId === this.props.user.data.id);
		const lastGameWeek = deadlineWeek ? deadlineWeek : (displayWeek ? displayWeek + 1 : displayWeek);

		return (
			<Layout>
				{
					(visibleWeekId && lastGameWeek &&
						<Block>
							<MatchDaySelector day={visibleWeekId}
								max={lastGameWeek - 1}
								min={1}
								match={match}
								onNext={(e: any) => this.props.onDayChange('next')}
								onPrev={(e: any) => this.props.onDayChange('prev')} />
						</Block>)
					|| null
				}
				{
					(initializedExternally && <Row>
						<Col lg={12} md={24} sm={24} xs={24}>
							<Block>
								<Stats generalPoints={teamPointsInfo.generalPoints}
									generalRank={teamPointsInfo.generalRank}
									visibleWeekPoints={teamPointsInfo.visibleWeekPoints}
									visibleWeekRank={teamPointsInfo.visibleWeekRank} />
							</Block>

							<Block>
								<Team widthRatio={15}
									heightRatio={13}
									clubs={this.props.clubs.data}
									bg={teamBackground}
									captainId={captainId}
									centerAligned={true}
									selection={startingByPositions}
									assetsCdn={application.competition.assetsCdn}
									showPlayerStatsPoints={true}
									modalEnabled={true}
									playerType={PlayerType.SoccerShirt}
									playerBadgeColor={theme.primaryColor}
									playerBadgeBgColor={theme.primaryColor}
									showCaptainBadge={false}
									playerPointsColor="#fff"
									playerPointsBgColor="#84FF00" />
								{
									// (teamOwner && this.props.application.competition.benchSize &&
										<Substitutes
											selection={bench}
											clubs={this.props.clubs.data}
											title="Bank"
											bgColor="#F0F3F8"
											modalEnabled={true}
											playerType={PlayerType.SoccerShirt}
											assetsCdn={application.competition.assetsCdn}
											playerBadgeColor="#000"
											playerBadgeBgColor={theme.primaryColor}
											playerPointsColor="#fff"
											playerPointsBgColor="#08003b"
										/>
								}
							</Block>
						</Col>
						<Col lg={12} md={24} sm={24} xs={24}>
						{
							this.props.matches.data && this.props.matches.data.length && visibleWeekId ? <Block>
								<FootballCalendar
									data={this.props.matches.data}
									size={40}
									t={this.props.t}
									weekId={visibleWeekId}
									showHeader={false}
									assetsCdn={this.props.application.competition.assetsCdn}
									isLoading={this.props.matches.isFetching} />
							</Block> : null
						}
						</Col>
					</Row>) || null
				}
			</Layout>
		);
	}
}

const WrappedComponent = AbstractTeam(TeamContainer, { type: 'football', mode: 'points' });


export default WrappedComponent;