import React from 'react';

import { CheckboxStyle } from './CheckboxStyle';
import { CheckboxProps } from 'antd/lib/checkbox';

interface Props { }
type MyCheckboxProps = CheckboxProps & Props;
export const Checkbox = (props: MyCheckboxProps) => {
	const { ...rest } = props;
	return (<CheckboxStyle {...rest} />);
}

export default Checkbox;
