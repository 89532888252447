import {ClubsAction} from '../actions/clubs';
import {ClubsState, Club} from '../types/index';
import {
	CLUBS_LOADING_STARTED,
	CLUBS_FETCHED_WITH_SUCCESS,
	CLUBS_FETCH_FAILED
} from '../actions/actions-types';


const emptyClubsList: Club[] = [];

const defaultState = {
	isFetching: false,
	loaded: false,
	data: emptyClubsList
};

export function clubsReducer(state: ClubsState = defaultState, action: ClubsAction) {
	switch (action.type) {
		case CLUBS_LOADING_STARTED:
			return {...state, isFetching: true, loaded: false};
		case CLUBS_FETCH_FAILED:
			return {...state, isFetching: false, loaded: true};
		case CLUBS_FETCHED_WITH_SUCCESS:
			return {...state, isFetching: false, data: action.data, loaded: true};
	}
	return state;
}