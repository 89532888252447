import React, { Component } from "react";
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from "react-router";
import { TransProps } from 'react-i18next';
import i18next from 'i18next';
import { pick } from 'lodash';
import lockr from 'lockr';
import { useTranslation } from "react-i18next";
import { Icon, Modal, Tooltip } from 'antd';
import Countdown from 'react-countdown-now';
import moment from 'moment';

import { HeaderStyle, Logo, Nav, Hamburger, HeaderBackground, TopHeaderStyle, MobileTabInfoStyle } from "./HeaderStyle";
import { withRouter } from 'react-router-dom';
import { Layout } from "./../../components";
import UILink from '../UI/Link/Link';

import * as userActions from '../../actions/user'
import * as playersActions from '../../actions/players'
import * as clubsActions from '../../actions/clubs'
import * as matchesActions from '../../actions/matches'
import * as teamsActions from '../../actions/teams'
import { StoreState, UserState, ApplicationState, MatchesState } from './../../types';
import { TeamIcon } from '../../pages/TeamContainer/TeamContainer'

export const staticPagesTitleMap: { [key: string]: string } = {
	'/stats': 'STATS',
	'/my-teams': 'MIJN TEAMS',
	'/rankings': 'KLASSEMENT',
	'/prizes': 'PRIJZEN',
	'/home': 'HOME',
	'/login': 'LOGIN',
	'/register': 'REGISTER',
	'/rules': 'SPELREGELS',
	'/deadlines': 'DEADLINES',
	'/new': 'NIEUW TEAM',
	'/my-teams/payments': 'BETALINGEN'
};

type HeaderProps = RouteComponentProps & TransProps & {
	user: UserState;
	application: ApplicationState;
	matches: MatchesState;
	fetchPlayers: any;
	fetchClubs: any;
	fetchMatchesInfo: any;
	fetchMatches: any;
	fetchUserTeamsAndLeagues: any;
	fetchTeamLeagues: any;
	logout: any;
	match: any;
	t: i18next.TFunction;
}

class Header extends Component<HeaderProps> {

	state = {
		windowWidth: window.innerWidth,
		menuToggled: false,
		subscriptions: 0,
		pageTitle: '',
		showTeams: false,
		promotions: [],
		activePromo: {message: '', weekId: 0, competitionFeed: ''},
		promoModalVisible: false,
	};

	componentDidMount() {
		this.props.fetchPlayers(
			this.props.application.competition.competitionFeed,
			this.props.application.competition.seasonId
		);

		this.props.fetchClubs(
			this.props.application.competition.competitionFeed,
			this.props.application.competition.seasonId
		);

		this.props.fetchMatchesInfo(
			this.props.application.competition.competitionFeed,
			this.props.application.competition.seasonId
		);

		this.props.fetchMatches(
			this.props.application.competition.competitionFeed,
			this.props.application.competition.seasonId
		);

		if (this.props.user.data.id) {
			this.props.fetchUserTeamsAndLeagues(
				this.props.application.competition.competitionFeed
			);
		}

		teamsActions.getSubscriptions(
			this.props.application.competition.competitionFeed,
			this.props.application.competition.seasonId
		)
			.then(result => {
				this.setState({ subscriptions: result.total });
			})

		matchesActions.getPromotions(
			this.props.application.competition.competitionFeed,
			this.props.application.competition.seasonId
		)
			.then(result => {
				this.setState({ promotions: result });
			})

		this._checkCurrentPageTitle();
	}

	componentDidUpdate(prevProps: HeaderProps, prevState: any) {
		if (this.props.location !== prevProps.location) {
			this._checkCurrentPageTitle();
		}

		if(prevProps.matches.info.deadlineWeek !== this.props.matches.info.deadlineWeek) {
			this.setActivePromo();
		}

		if(prevState.promotions.length !== this.state.promotions.length) {
			this.setActivePromo();
		}

		if(prevState.activePromo !== this.state.activePromo) {
			this.setPromoModalVisible(true);
		}
	}

	_checkCurrentPageTitle = () => {
		if (staticPagesTitleMap[this.props.location.pathname]) {
			this.setState({ pageTitle: staticPagesTitleMap[this.props.location.pathname] });
		} else if (this.props.location.pathname.includes('my-teams/team/')) {
			const teamId = this.props.location.pathname
				.split('/')
				.find((chunk: any) => !!parseInt(chunk));

			const team = teamId && this.props.user.teams.find((team: any) => team.id === parseInt(teamId));

			if (team) {
				this.setState({ pageTitle: team.name });
			}
		} else {
			this.setState({ pageTitle: '' });
		}

		// @ts-ignore
		window.dataLayer.push({
			event: 'screen_change',
			screen_name: this.state.pageTitle,
			game: this.props.application.competition.competitionFeed,
			login_status: !!this.props.user.authenticated ? 'logged in' : 'logged out',
			user_id: this.props.user && this.props.user.data && this.props.user.data.id ? this.props.user.data.id : null
		});
	};

	navOpen: boolean = false;

	_onPressHamburger = (ev: any) => {
		const body = document.getElementsByTagName("body")[0];

		if (this.navOpen) {
			body.style.transform = "";
		} else {
			body.style.transform = "translateX(80vw)";
		}

		this.navOpen = !this.navOpen;

		this._openSubMenu(ev);
	};

	_openSubMenu = (ev: any) => {
		this.setState({ menuToggled: !this.state.menuToggled })
	};

	_onShowTeamsToggle = (event: any) => {
		event.stopPropagation();
		event.preventDefault();
		this.setState({ showTeams: !this.state.showTeams });
	};

	onMenuItemNavigate = (ev: any) => {
		if (this.state.windowWidth < 768) {
			this._onPressHamburger(ev);
		}
	};

	onLogOut = (e: any) => {
		this.props.logout();
	};

	setActivePromo() {
		const promo:any = this.state.promotions.find((promo: any) => { return promo.weekId === this.props.matches.info.deadlineWeek });

		if(promo) {
			const promoModalShown:any = lockr.get('promoModalShown');

			if(promoModalShown) {
				const shown = promoModalShown.find((shown: any) => { return shown.weekId === promo.weekId && shown.competitionFeed===promo.competitionFeed });
				
				if(!shown) {
					this.setState({activePromo: promo});
				}
			} else {
				this.setState({activePromo: promo});
			}			
		}
	}

	setPromoModalVisible(promoModalVisible: any) {
		this.setState({promoModalVisible: true});

		
		if(!promoModalVisible) {
			//lockr
			this.setState({promoModalVisible: false});
			this.setModalShown();
			console.log("SAVE PREF FOR GAME WEEK AND GAME AND SEASON NOT TO SHOW AGAIN");
		}
	}

	setModalShown() {
		let promoModalShown:any = lockr.get('promoModalShown');

		if(promoModalShown) {
			promoModalShown.push({
				competitionFeed: this.state.activePromo.competitionFeed,
				seasonId: 2019,
				weekId: this.state.activePromo.weekId
			});
		} else {
			promoModalShown = [{
				competitionFeed: this.state.activePromo.competitionFeed,
				seasonId: 2019,
				weekId: this.state.activePromo.weekId
			}];
		}

		lockr.set('promoModalShown', promoModalShown);
		this.setState({promoModalVisible: false});
	}

	render() {
		const { user, matches, location, match, t } = this.props;
		const { subscriptions, pageTitle, menuToggled, showTeams, activePromo } = this.state;
		const userTeam = user.teams[0];
		const showPointsAndTransfers = userTeam && matches.info.deadlineWeek && (matches.info.deadlineWeek > userTeam.weekId);
		const isActive = (match: string, exact?: boolean) => exact ? location.pathname === match : location.pathname.indexOf(match) !== -1;
		const gameOver = !matches.info.deadlineWeek;
		const gameInProgress = !!matches.info.deadlineWeek;
		const teams = (user && user.teams) || [];

		let allMenuItems: string[] = ['home', 'deadlines', 'stats', 'rankings', 'prizes', 'rules' ];
		let menuTotalItems = 5;

		let visibleMenuItems: string[] = ([] as string[]).concat(allMenuItems);
		let collapsedMenuItems: string[] = [];

		const insertToMenuAtPosition = (positionIndex: number, item: string) => {
			if (allMenuItems.indexOf(item) === -1) {
				allMenuItems.splice(positionIndex, 0, item);
				visibleMenuItems = ([] as string[]).concat(allMenuItems);
				menuTotalItems += 1;
			}
		};

		if (user.authenticated) {
			insertToMenuAtPosition(1, 'teams');
			insertToMenuAtPosition(6, 'new');
		}

		const isCollapsed = (menuItem: string) => collapsedMenuItems.indexOf(menuItem) !== -1;
		const isVisible = (menuItem: string) => visibleMenuItems.indexOf(menuItem) !== -1;

		const deadlineRenderer = ({ days, hours, minutes, seconds }: any) => {
			return <span>
				{days}d {hours}u {minutes}m {seconds}s
			</span>;
		};

		const deadlineWeek = (this.props.matches && this.props.matches.info && this.props.matches.info.deadlineWeek) || 0;
		const deadlineDate = this.props.matches && this.props.matches.info && this.props.matches.info.deadlineDate;
		const deadlineEpochMilliseconds = deadlineDate ? moment(deadlineDate).valueOf() : 0;

		return (
			<React.Fragment>
				<Layout>
					<TopHeaderStyle>
						<ul className="games">
							{/* <li><UILink type={'external'}href={'https://goudenf1.hln.be'}>GOUDEN F1</UILink></li> */}
							{/* <li><UILink type={'external'} className="active" href={'https://goudenschoenmanager.hln.be'}>Gouden Schoen Manager</UILink></li> */}
							{/* <li><UILink type={'external'} href={'https://goudenklassiekers.hln.be'}>KLASSIEKERS</UILink></li>
							<li><UILink type={'external'} href={'https://goudengiro.hln.be'}>GIRO</UILink></li>
							<li><UILink type={'external'} href={'https://goudentour.hln.be'}>TOUR</UILink></li> */}
							{/* <li><UILink type={'external'} href={'https://goudenvuelta.hln.be'}>GOUDEN VUELTA</UILink></li> */}
							{/* <li><UILink type={'external'} href={'https://goudencross.hln.be'}>CROSS</UILink></li> */}
						</ul>
						<ul>
							{
								deadlineEpochMilliseconds ?
									<li style={{ backgroundColor: '#464646', textTransform: 'uppercase', padding: '5px 10px', color: '#fff' }}>
										Deadline {deadlineWeek ? `Speeldag ${deadlineWeek}` : ''}:  <Countdown
											renderer={deadlineRenderer}
											date={deadlineEpochMilliseconds} />

									</li>
									: null
							}

							{
								deadlineEpochMilliseconds ?
									<li>|</li> : null
							}

							<li>
								{subscriptions > 0 ? <span> {subscriptions} deelnemende ploegen </span> : null}
							</li>

							{
								user.authenticated ?
									<li><a href={'https://mijnomgeving.hln.be/'}>{`Welkom ${user.data.firstName}`}</a></li> :
									null
							}

							{
								user.authenticated ?
									<li><Link onClick={this.onLogOut} to={`/home`}>Uitloggen</Link></li> :
									null
							}

						</ul>
					</TopHeaderStyle>
				</Layout>
				
				<HeaderBackground>
					<Layout style={{ background: 'transparent' }}>
						<HeaderStyle>
							{
								!menuToggled ?
									<Link className="game-name" to={'/home'} style={{fontFamily: 'HLN-Medium', fontSize: '1em'}}>
										<img src="https://goudenspelen.s3.eu-west-1.amazonaws.com/files/logo-hln.svg" style={{height: '75px', margin: '10px 5px 10px 0px'}}/>
										{this.props.t(`game.${this.props.application.competition.competitionFeed}.name`)}
									</Link>
									: null
							}
							<Hamburger onClick={this._onPressHamburger}>
								<Icon type="menu" />
								{pageTitle}
								<MobileTabInfoStyle>
									<span>{subscriptions > 0 ? <span> {subscriptions} deelnemende ploegen </span> : null}</span>
									{
										deadlineEpochMilliseconds ?
											<span>
												Deadline {deadlineWeek ? `Speeldag ${deadlineWeek} ` : ''}
												<Countdown
													renderer={deadlineRenderer}
													date={deadlineEpochMilliseconds} />

											</span>
											: null
									}
								</MobileTabInfoStyle>
							</Hamburger>
							<Nav>
								<ul>
									<li className={`mobile-only ${isActive('home') || location.pathname === '/' ? 'active' : ''}`}>
										<Link onClick={this.onMenuItemNavigate} to="/home">
											<Icon type="home" />
											{staticPagesTitleMap['/home']}
										</Link>
									</li>

									{user.authenticated && (isVisible('teams') &&
										<li className={`${isActive('/my-teams', true) ? 'active' : ''}`}>
											<Link onClick={this.onMenuItemNavigate} to="/my-teams">
												<TeamIcon />
												{staticPagesTitleMap['/my-teams']}
												<Icon onClick={this._onShowTeamsToggle} className={'teams-toggle'} type={!showTeams ? "caret-down" : "caret-up"} />
											</Link></li>) || null}

									{
										showTeams ? teams.map((team: any) => {
											return <li key={`key-${team.id}`} className={`mobile-only team-item ${isActive(`my-teams/team/${team.id}`) ? 'active' : ''}`} >
												<Link
													onClick={this.onMenuItemNavigate}
													key={team.id}
													to={`/my-teams/team/${team.id}`}>
													<span className="team-name">
														{
															team.paid ?
																<Tooltip title="Betaald">
																	<span className="badge"> <Icon type="check-circle" /></span>
																</Tooltip> :
																<Tooltip title="Niet betaald">
																	<span className="badge"> <Icon type="exclamation-circle" /></span>
																</Tooltip>
														}
														{team.name}
													</span>
												</Link>
											</li>;
										}) : null
									}
									{
										user.authenticated ?
											<li className={`mobile-only ${isActive(`my-teams/payments`) ? 'active' : null}`}>
												<Link
													onClick={this.onMenuItemNavigate}
													to={`/my-teams/payments`}>
													<Icon type="credit-card" theme="filled" />
													{staticPagesTitleMap['/my-teams/payments']}
												</Link>
											</li>
											: null
									}

									{(isVisible('deadlines') &&
										<li className={`${isActive('deadlines') ? 'active' : ''}`}>
											<Link onClick={this.onMenuItemNavigate} to="/deadlines">
												<Icon type="read" />
												{staticPagesTitleMap['/deadlines']}
											</Link>
										</li>) || null}

									{(isVisible('stats') &&
										<li className={`${isActive('stats') ? 'active' : ''}`}>
											<Link onClick={this.onMenuItemNavigate} to="/stats">
												<Icon type="star" />
												{staticPagesTitleMap['/stats']}
											</Link></li>) || null}
									
									{(isVisible('rankings') &&
										<li className={`${isActive('rankings') ? 'active' : ''}`}>
											<Link onClick={this.onMenuItemNavigate} to="/rankings">
												<Icon type="crown" />
												{staticPagesTitleMap['/rankings']}
											</Link></li>) || null}

									{(isVisible('prizes') &&
										<li className={`${isActive('prizes') ? 'active' : ''}`}>
											<Link onClick={this.onMenuItemNavigate} to="/prizes">
												<Icon type="euro" />
												{staticPagesTitleMap['/prizes']}
											</Link>
										</li>) || null}

									{(isVisible('rules') &&
										<li className={`${isActive('rules') ? 'active' : ''}`}>
											<Link onClick={this.onMenuItemNavigate} to="/rules">
												<Icon type="read" />
												{staticPagesTitleMap['/rules']}
											</Link>
										</li>) || null}

									{(isVisible('new') && deadlineWeek &&
										<li className={`new-team ${isActive('new') ? 'active' : ''}`}>
											<Link onClick={this.onMenuItemNavigate} to="/new">
												<Icon type="plus-circle" />
												{staticPagesTitleMap['/new']}
											</Link>
										</li>) || null}

									{
										user.authenticated ?
											<li className={'mobile-only user'}>
												<a href={'https://mijnomgeving.hln.be/'}>
													<Icon type="user" />
													Mijn profiel
												</a>
											</li> : null
									}
									{
										user.authenticated ?
											<li className={'mobile-only logout'}>
												<Link onClick={this.onLogOut} to="/home">
													<Icon type="logout" />
													Log uit
											</Link>
											</li> : null
									}
									{
										!user.authenticated ?
											<li className={'mobile-only logout'}>
												<Link onClick={this.onMenuItemNavigate} to="/login">
													<Icon type="login" />
													{staticPagesTitleMap['/login']}
												</Link>
											</li> : null
									}
								</ul>
							</Nav>
						</HeaderStyle>
					</Layout>
				</HeaderBackground>
				{ activePromo ? <Modal
					title="1+1 ACTIE"
					style={{ top: 20 }}
					visible={this.state.promoModalVisible}
					onCancel={() => this.setModalShown()}
					onOk={() => this.setPromoModalVisible(false)}
				>
					<p>{activePromo.message}</p>
				</Modal>
				: null}
			</React.Fragment>
		);
	}
}

export const mapDispatchToProps = {
	fetchPlayers: playersActions.fetchPlayers,
	fetchClubs: clubsActions.fetchClubs,
	fetchMatchesInfo: matchesActions.fetchMatchesInfo,
	fetchMatches: matchesActions.fetchMatches,
	fetchUserTeamsAndLeagues: userActions.fetchTeams,
	fetchTeamLeagues: userActions.fetchUserTeamLeaguesAndPersist,
	logout: userActions.logOutUser,
};

export function mapStateToProps({ user, application, matches }: StoreState.All) {
	return {
		user,
		application,
		matches
	}
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Header)
);
