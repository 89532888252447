import styled from 'styled-components';

const Overview = styled.div`
    align-items: center;
		padding-bottom:15px;

		.header {
			color: #FFF;
			background-color: #08003b;
			padding: 5px;

			.ant-col {
				text-align: center;
			}
		}
`;

const Value = styled.span`
	font-size: 1.2em;
	font-weight: bold;
`;

const Label = styled.span`
	font-size: 1em;
`;

export {
    Overview,
    Value,
		Label
};
