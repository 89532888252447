import styled from "../../styles/styled-components";
import { mediaQueries } from "../../styles/media-queries";
import { H2, H1 } from "../../styles/global";

const LoginStyle = styled.div`
	margin: 40px;

	.login-form {
		margin: 20px 60px;
	}

	h4 {
		font-family: HLN-Bold;
		font-size: 2em;
		color: #FFF;
	}
`;

export {
	LoginStyle,
};