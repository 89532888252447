import React, {Component} from "react";
import {LinkStyle, AnchorStyle} from "./LinkStyle";
import {LinkProps} from "react-router-dom";

export const Link = (props: any) => {
	const {children, ...rest} = props;

	return props.type && props.type === 'external' ?
		(<AnchorStyle
			{...rest}>
			{children}
		</AnchorStyle>)
		: (<LinkStyle
		{...rest}>
		{children}
	</LinkStyle>);
};

export default Link;
