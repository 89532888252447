import i18next from 'i18next';
import { initReactI18next } from "react-i18next";
import en from './locales/en.json';
import nl from './locales/nl.json';

i18next
	.use(initReactI18next)
	.init({
		interpolation: {
			escapeValue: false,
		},
		lng: 'nl',
		fallbackLng: 'en',
		resources: {
			en: { translation: en },
			nl: { translation: nl },
		},
		react: {
      wait: true,
    }
	});

export default i18next