import {applicationReducer} from './application';
import {userReducer} from './user';
import {playersReducer} from './players';
import {clubsReducer} from './clubs';
import {matchesReducer} from './matches';
import {combineReducers} from 'redux';

const reducer = combineReducers({
	user: userReducer,
	players: playersReducer,
	application: applicationReducer,
	clubs: clubsReducer,
	matches: matchesReducer
});

export default reducer;