import styled from "styled-components";
import {Button} from 'antd';

import 'antd/lib/button/style';

export const ButtonStyle = styled(Button)`
	height: auto;
	padding: 10px 20px;
	// border: 1px solid #F5D555;
	border-radius: 0px;
	color: #FFF;
	background: #F00;
	// color: #000;
	// background: transparent linear-gradient(96deg, #F5D555 0%, #F5D555 19%, #FDEFA7 48%, #F4CF41 100%) 0% 0% no-repeat padding-box;

	// &.ant-btn-primary:hover, &.ant-btn-primary:focus {
	// 	background: transparent linear-gradient(96deg, #F5D555 0%, #F5D555 19%, #FDEFA7 48%, #F4CF41 100%) 0% 0% no-repeat padding-box;
	// }

    &.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
		padding-left: 40px;
		
		&.ant-btn-sm {
			padding-left: 21.5px;

			span {
				margin-left: 5px;
			}
		}
    }

    &.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon {
        margin-left: 0;
    }

	&.ant-btn-danger {
		color: #000
	}
`;