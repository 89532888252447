import React from 'react';

import {LayoutStyle} from './LayoutStyle';
import {LayoutProps} from 'antd/lib/layout';

interface Props {
}

type MyLayoutProps = LayoutProps & Props;
export const Layout = (props: MyLayoutProps) => {
	const {...rest} = props;
	return (<LayoutStyle {...rest} />);
}

export default Layout;
