import React, { Component } from "react";
import parseHTML from 'html-react-parser';
import { connect } from 'react-redux';
import { Layout, Block, Row, Col } from '../../components';
import * as userActions from '../../actions/user'
import { StoreState, ApplicationState } from './../../types';

type TermsProps = {
	application: ApplicationState;

}

class Terms extends Component<TermsProps> {
	state = {
		text: ''
	}

	componentDidMount() {
		userActions.getPage(this.props.application.competition.competitionFeed, 'terms')
			.then((result: any) => {
				const body = result && result[0] && result[0].translations && result[0].translations[0] && result[0].translations[0].body;
				this.setState({ text: body })
			});
	}

	render() {
		return (
			<Layout><Row>
				<Col span={24}>
					<Block>
					{ parseHTML(this.state.text) }
					</Block>
				</Col>
			</Row></Layout>
		);
	}
}

export function mapStateToProps({ application }: StoreState.All) {
	return {
		application
	}
}

export default connect(mapStateToProps, {})(Terms);
