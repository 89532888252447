import React, {Component} from 'react';

import {StatsStyle, RowStyle, PointStyle} from './StatsStyle';

export interface StatsOverviewState {
}

export interface StatsOverviewProps {
	generalPoints: number | string;
	generalRank: number | string;
	visibleWeekPoints: number | string;
	visibleWeekRank: number | string;
}

class Stats extends Component<StatsOverviewProps, StatsOverviewState> {
	render() {
		return (
			<StatsStyle>
				<RowStyle>
					<PointStyle>
						Punten speeldag
						<span>{this.props.visibleWeekPoints}</span>
					</PointStyle>
					<PointStyle>
						Punten totaal
						<span>{this.props.generalPoints}</span>
					</PointStyle>
				</RowStyle>
				<RowStyle>
					<PointStyle>
						<span>{this.props.visibleWeekRank}</span>
						Plaats speeldag
					</PointStyle>
					<PointStyle>
						<span>{this.props.generalRank}</span>
						Plaats algemeen
					</PointStyle>
				</RowStyle>
			</StatsStyle>
		)
	}
}

export default Stats;