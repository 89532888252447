import styled from "styled-components";

const PortraitStyle = styled.div`
    position: relative;
    height: ${(props: any) => props.parentSize || '55px'};
    width: ${(props: any) => props.parentSize || '55px'};
` as any;

const PortraitBg = styled.div`
    position: absolute;
    top: 2.5px;
    left: 2.5px;
    
    height: ${(props: any) => `${props.size}`} ;
    width: ${(props: any) => `${props.size}`} ;

    display: flex;
    align-items: center;
    justify-content: center;

    background: url(${(props: any) => props.bg}) no-repeat center center/contain;
` as any;

const PortraitSoccerJersey = styled.div`
    position: absolute;
    bottom: -22.5px;
    height: 55px;
    width: 55px;
    background: url(${(props: any) => props.bg}) no-repeat center center/contain;
` as any;

const PortraitIcon = styled.div`
    position: absolute;
    bottom: 0;
    left: -5px;
    width: 20px;
    height: 20px;
	background: url(${(props: any) => props.icon}) no-repeat center center/contain;
` as any;

export {
	PortraitBg,
	PortraitStyle,
	PortraitSoccerJersey,
	PortraitIcon
};
