import styled from "../../styles/styled-components";
import { mediaQueries } from "../../styles/media-queries";
import { H2, H1 } from "../../styles/global";

const RegisterStyle = styled.div`
	${H2} {
		text-align: center;
		margin: 40px 0;
	}
`;

export {
	RegisterStyle,
};