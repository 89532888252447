import styled from 'styled-components';

const MatchDaySelectorStyle = styled.div`
    position: relative;
    background: #F0F3F8;
    text-align: center;
    padding: 5px 0;

    font-size: 18;
    font-weight: bold;
    color: black;

    display: flex;
    align-items: center;
    justify-content: space-between;

    -webkit-user-select: none;     
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
`;

const ButtonStyle = styled.a`
    display: block;
    background: green;
    width: 25px;
    height: 25px;
    cursor: pointer;
    background: url(/assets/img/arrow.svg) center center no-repeat;
    background-size: 20px 20px;
    margin: 0 0 0 5px;
    
    &.disabled {
    	cursor: not-allowed;
		}
		
    ${({ type }) => type === 'next' && `
        transform: rotate(180deg);
        margin: 0 5px 0 0;
    `}
`;

export {
	MatchDaySelectorStyle,
	ButtonStyle
};