import styled from "../../styles/styled-components";
import { mediaQueries } from "../../styles/media-queries";
import { PlayerStyle, Badge } from "../Player/PlayerStyle";

const SubstitutesStyle = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: rgba(0,0,0,0.2);
	padding-bottom: 5%;
	margin-top: 50px;

	.substitutes {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		width: 100%;

		${PlayerStyle} {
			margin: 10px;
		}
	}
` as any;

const Title = styled.h3`
	margin-bottom: 20px;
	background-color: #F00;
	color: #FFF;
	position: relative;
  	top: -30px;
	padding: 5px 30px;
	font-size: 14px;
`;

export {
	SubstitutesStyle,
	Title
};