import {PlayersAction} from '../actions/players';
import {PlayersState, Player} from '../types/index';
import {
	PLAYERS_LOADING_STARTED,
	PLAYERS_FETCHED_WITH_SUCCESS,
	PLAYERS_FETCH_FAILED
} from '../actions/actions-types';


const emptyPlayersList: Player[] = [];

const defaultState = {
	isFetching: false,
	loaded: false,
	data: emptyPlayersList
};

export function playersReducer(state: PlayersState = defaultState, action: PlayersAction) {
	switch (action.type) {
		case PLAYERS_LOADING_STARTED:
			return {...state, isFetching: true, loaded: false};
		case PLAYERS_FETCH_FAILED:
			return {...state, isFetching: false, loaded: true};
		case PLAYERS_FETCHED_WITH_SUCCESS:
			return {...state, isFetching: false, data: action.data, loaded: true};
	}
	return state;
}