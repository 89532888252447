import React, {Component} from "react";
import {PopoverStyle} from "./PopoverStyle";
import {PopoverProps} from "antd/lib/popover";

export const Popover = (props: any) => {
	const {children, ...rest} = props;

	return (<PopoverStyle
		{...rest}>
		{children}
	</PopoverStyle>);
};

export default Popover;
