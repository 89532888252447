import styled from 'styled-components';
import {Row, Col} from 'antd';
import { mediaQueries } from "../../../styles/media-queries";


import 'antd/lib/grid/style';

export const RowStyle = styled(Row)`	
	&.ant-row {

	}
`;

export const ColStyle = styled(Col)`
	&.ant-col {

	}
`;