import React from 'react';

import {RowStyle, ColStyle} from './GridStyle';
import {RowProps, ColProps} from 'antd/lib/grid';

export const Row = (props: RowProps) => {
	const {...rest} = props;
	return (<RowStyle {...rest} />);
};

export const Col = (props: ColProps) => {
	const {...rest} = props;
	return (<ColStyle {...rest} />);
};