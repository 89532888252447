import styled from "../../styles/styled-components";
import { mediaQueries } from "../../styles/media-queries";

const TeamContainerStyles = styled.div`
	@media (max-width: 575px) {
		margin-top: -20px;
	}
`;

const TeamHeaderStyle = styled.div`
	background-color: ${(props: any) => props.theme.primaryColor};

	ul {
		list-style: none;
		display: flex;
    	align-items: stretch;
		justify-content: space-between;
		padding: 0px;

		li {
			display: block;
			flex: 0 1 auto;
			list-style-type: none;
			width: 100%;
			text-align: center;
			padding: 5px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;

			&.active {
				border-bottom: 2px solid #FFF;
			}

			a {
				color: #FFF;
				display: inline-block;
				width: 100%;

				.anticon {
					color: #FFF;
					@media ${mediaQueries.tablet} {
						margin-right: 5px;
						margin-top: 5px;
					}
				}
			}
		}
	}
`;

export {
	TeamHeaderStyle,
	TeamContainerStyles
};