import React, {Component} from "react";
import {TabsStyle} from "./TabsStyle";
import {TabsProps} from "antd/lib/tabs";

export const Tabs = (props: any) => {
	const {children, ...rest} = props;

	return (<TabsStyle
		{...rest}>
		{children}
	</TabsStyle>);
};

export default Tabs;
