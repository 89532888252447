import styled from "styled-components";
import { Table } from "antd";

import { mediaQueries } from "../../styles/media-queries";

import "antd/lib/table/style";

const ContainerStyle = styled.div`
	.ant-input {
		border: none !important;
	}
`;

const SelectGroupStyle = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-top: 10px;
	margin-bottom: 10px;
	
	> div {
		margin: 5px 0;
	}

	@media ${mediaQueries.tablet} {
		flex-wrap: nowrap;
		justify-content: space-between;

		> div {
			margin: 0 5px;
		}
	}
`;

const tablePagination = `
.ant-pagination {
	width: 100%;
	text-align: center;

	.ant-pagination-prev, .ant-pagination-next {
		a {
			border-radius: 0px;
		}

		&:hover:not(.ant-pagination-disabled) a {
			background-color: #F00;
			border-color: #F00;
			color: #FFF;
		}
	}

	.ant-pagination-item {
		border-radius: 0px;

		&:hover:not(.ant-pagination-disabled), &.ant-pagination-item-active {
			background-color: #F00;
			border-color: #F00;

			a {
				font-weight: bold;
				color: #FFF;
			}
		}
	}

	.ant-pagination-disabled {
		a {
			border-color: #F0F3F8;
			background: #F0F3F8;
			font-weight: bold;
			color: #ababab;
		}
	}
}
`;

const TableStyle = styled(Table)`
	background: white;

	.ant-table-thead {
		th {
			background: #F00;
			color: #FFF !important;
		}
	}

	.ant-table-tbody {
		.ant-table-row {
			> td {
				border: none;
				padding: 7.5px;

				&:first-child {
					padding: 2.5px 10px 0 15px;
				}
			}

			&--even {
				background-color: #F0F3F8;

				&:hover > td {
					background-color: #F0F3F8 !important;
				}
			}

			&--odd:hover > td {
				// background-color: white !important;
			}
		}

		.cursor-pointer {
			cursor: pointer;
		}
	}

	${tablePagination}	
` as any;

const BasicTableStyle = styled(Table)`
	background: white;
				border-radius: 0px;
color: #FFF;
	
	.ant-table-content {
		overflow-x: scroll;
	}

	.ant-table-thead {
				border-radius: 0px;
				color: #FFF;

		th {
			background: #F00;
			color: #FFF !important;
			&:last-child {
				text-align: right;
				padding-right: 20px;
			}
		}

		> tr { 
			&:first-child, :last-child { 
				> th {
					&:first-child, :last-child {
						border-radius: 0;
					}
				}
			}
		}
	}

	.ant-table-tbody {
		.ant-table-row {
			> td {
				border: none;
				padding: 7.5px 16px;
				&:last-child {
					text-align: right;
				}
			}

			&--even {
				background-color: #F0F3F8;

				&:hover > td {
					background-color: #F0F3F8 !important;
				}
			}

			&--odd:hover > td {
				background-color: white !important;
			}
		}

		.cursor-pointer {
			cursor: pointer;
		}
	}

	${tablePagination}
` as any;

const TableListStyle = styled(Table)`
	background: white;
	
	.ant-table-content {
		overflow-x: scroll;
	}

	.ant-table-thead {
		th {
			background: #F00;
			color: #FFF !important;
			&:last-child {

			}
		}

		> tr { 
			&:first-child, :last-child { 
				> th {
					&:first-child, :last-child {
						border-radius: 0;
					}
				}
			}
		}
	}

	.ant-table-tbody {
		.ant-table-row {
			> td {
				border: none;
				padding: 7.5px 16px;
				&:last-child {
				}
			}

			&--even {
				background-color: #F0F3F8;

				&:hover > td {
					background-color: #F0F3F8 !important;
				}
			}

			&--odd:hover > td {
				background-color: white !important;
			}
		}

		.cursor-pointer {
			cursor: pointer;
		}
	}

	${tablePagination}
` as any;

const PlayerStyle = styled.div`
	${(props: any) =>
		props.type === "desktop" &&
		`
		display: none;
	`}
	
	@media ${mediaQueries.mobileL} {
		${(props: any) =>
			props.type === "desktop" &&
			`
			display: block;
		`}
	}

	${(props: any) =>
		props.type === "mobile" &&
		`
		display: block;
	`}

	@media ${mediaQueries.mobileL} {
		${(props: any) =>
			props.type === "mobile" &&
			`
			display: none;
		`}
	}

	p {
		margin-bottom: 0;
		font-size: 12px;

		&:first-child {
			font-weight: bold;
		}

		&:last-child {
			color: ${(props: any) => props.clubColor};

			span {
				${(props: any) =>
					props.position === "gk" &&
					`
					color: ${props.theme.positionGk};
				`}

				${(props: any) =>
					props.position === "df" &&
					`
					color: ${props.theme.positionDf};
				`}

				${(props: any) =>
					props.position === "mf" &&
					`
					color: ${props.theme.positionMf};
				`}

				${(props: any) =>
					props.position === "fw" &&
					`
					color: ${props.theme.positionFw};
				`}
			}
		}

	}
` as any;

export {
	ContainerStyle,
	SelectGroupStyle,
	PlayerStyle,
	TableStyle,
	BasicTableStyle,
	TableListStyle
};
